import { runInAction, makeAutoObservable } from "mobx";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import UIStore from "../../../../stores/UIStore";

export interface IRequest {
	name: string;
	email: string;
	password: string;
}

interface IPush {
	goBack: () => void;
}

export default class Store {
	private uiStore: UIStore;
	private history: IPush;

	public questions: API.NewQuestion[] = [];

	public hasImcValues: boolean = false;

	public addBlankImcValue = () => {
		if (this.imcValues === null) {
			this.imcValues = [{ imc: 0, value: 0 }];
		}

		this.imcValues.push({ imc: 0, value: 0 });
	};

	public removeImcValue = (imcValueIndex: number) => {
		if (this.imcValues !== null) {
			if (this.imcValues.length > 1) {
				this.imcValues = this.imcValues.filter(
					(_, index) => index !== imcValueIndex,
				);
			} else {
				this.uiStore.showSnackbar(
					"Desabilite o peso do IMC no questionário.",
				);
			}
		}
	};

	public imcValues: API.NewImcValue[] | null = null;

	public onChangeHasImcValues = () => {
		if (this.hasImcValues) {
			this.imcValues = null;
		} else {
			this.imcValues = [{ imc: 0, value: 0 }];
		}
		this.hasImcValues = !this.hasImcValues;
	};

	public addQuestionToQuiz = (question: API.NewQuestion) => {
		this.questions.push(question);
	};

	public deleteQuestion = (questionIndex: number) => {
		this.questions = this.questions.filter(
			(_, index) => index !== questionIndex,
		);
	};

	public results: API.NewQuizResult[] = [
		{ title: "", minimumValue: 0, description: "" },
	];

	public addBlankResult = () => {
		this.results.push({ title: "", minimumValue: 0, description: "" });
	};

	public removeResult = (resultIndex: number) => {
		if (this.results.length > 1) {
			this.results = this.results.filter(
				(_, index) => index !== resultIndex,
			);
		} else {
			this.uiStore.showSnackbar(
				"Questionários devem ter pelo menos um possível resultado com pontuação mínima 0.",
			);
		}
	};

	public loading = false;
	public error: string | null = null;

	constructor(uiStore: UIStore, history: IPush) {
		this.uiStore = uiStore;
		this.history = history;
		makeAutoObservable(this);
	}

	public createQuiz = async (quizData: API.NewQuiz) => {
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			runInAction(() => (this.loading = true));
			await API.createQuiz(quizData);
			this.history.goBack();
			this.uiStore.showSnackbar(Strings.quizz.create.success);
		} catch (error) {
			runInAction(() => (this.error = Errors.handleError(error)));
			this.uiStore.showErrorSnackbar(this.error);
		} finally {
			runInAction(() => (this.loading = false));
		}
	};
}
