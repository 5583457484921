import React from "react";
import useStyles from "./styles";
import {
	Dialog,
	DialogContent,
	Button,
	Typography,
	List,
	ListItem,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import CardForm from "../../../../components/CardForm";

interface IProps {
	isVisible: boolean;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResultsHelpDialog: React.FC<IProps> = (props) => {
	const classes = useStyles();

	const closeDialog = () => {
		props.setIsVisible(false);
	};

	return (
		<Dialog open={props.isVisible} maxWidth="lg" fullWidth={true}>
			<DialogContent>
				<CardForm
					title="Ajuda sobre resultados"
					backButtonDisabled={true}
					actionsFooter={
						<>
							<Button onClick={closeDialog}>Fechar</Button>
						</>
					}
				>
					<Typography variant="body1">
						A primeira opção de resultado cadastrada deverá ter o
						valor mínimo de pontuação zero.
						<br />
						<br />
						Cadastre outros resultados aumentando o valor mínimo
						para atingí-los. Dessa forma, as faixas de pontuação
						serão criadas.
						<br />
						<br />
					</Typography>
					<Typography variant="body1" className={classes.boldText}>
						Por exemplo:
					</Typography>
					<List>
						<ListItem>
							<Typography variant="body1">
								Resultado 1 - Título: Você tem ótimos hábitos! -
								Valor mínimo de pontos para atingir: 0.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Resultado 2 - Título: Você tem alguns bons
								hábitos e outros nem tanto! - Valor mínimo de
								pontos para atingir: 20.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Resultado 3 - Título: Cuidado com os maus
								hábitos! - Valor mínimo de pontos para atingir:
								40.
							</Typography>
						</ListItem>
					</List>

					<Typography variant="body1" className={classes.boldText}>
						<br />
						Como irão ficar os resultados?
					</Typography>

					<List>
						<ListItem>
							<Typography variant="body1">
								Com menos de 20 pontos, o usuário irá receber
								resultado 1.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Com 20 ou mais e menos de 40 pontos, o usuário
								irá receber o resultado 2.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Com 40 pontos ou mais, o usuário irá receber o
								resultado 3.
							</Typography>
						</ListItem>
					</List>
				</CardForm>
			</DialogContent>
		</Dialog>
	);
};

export default observer(ResultsHelpDialog);
