import React from "react";
import "./style.scss";
import { observer } from "mobx-react-lite";
import Strings from "../../../modules/Strings";
import { PageContainer } from "../../../components/PageContainer";
import { Box } from "@material-ui/core";

const Home = observer(() => {

	return (
		<PageContainer>
			<Box className="welcomeContainer" display="flex" flexDirection="column">
				{Strings.home.welcome}
			</Box>
		</PageContainer>
	);
});

export default Home;
