import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	CardContent,
	TextField,
	Divider,
	LinearProgress,
} from "@material-ui/core";
import Strings from "../../../../modules/Strings";
import CardForm from "../../../../components/CardForm";
import { useGlobalStore } from "../../../context";
import QuestionDetailsDialog from "../_Question/QuestionDetailsDialog";
import QuestionsTable from "./QuestionsTable";
import ResultsTable from "./ResultsTable";
import ImcValuesTable from "./ImcValuesTable";
import Store from "./store";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(id, uiStore));

	const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState<
		number | undefined
	>(undefined);

	return (
		<CardForm title={Strings.quizz.details.title}>
			{!store.quiz ? (
				<LinearProgress />
			) : (
				<>
					<CardContent>
						<TextField
							variant="standard"
							type="text"
							value={store.quiz?.name}
							label="Nome do questionário"
							disabled={true}
							className={classes.nameField}
						/>

						<Divider className={classes.divider} />

						<QuestionsTable
							store={store}
							setSelectedQuestionIndex={setSelectedQuestionIndex}
						/>

						<Divider className={classes.divider} />

						<ResultsTable store={store} />

						<Divider className={classes.divider} />

						<ImcValuesTable store={store} />
					</CardContent>

					{selectedQuestionIndex !== undefined && (
						<QuestionDetailsDialog
							question={
								store.quiz.questions[selectedQuestionIndex]
							}
							setSelectedQuestionIndex={setSelectedQuestionIndex}
						/>
					)}
				</>
			)}
		</CardForm>
	);
};

export default observer(DetailsView);
