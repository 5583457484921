import * as Yup from "yup";
import { questionValidationSchema } from "../_Question/CreateQuestionDialog/validation";

export const quizValidationSchema = Yup.object().shape({
	name: Yup.string().required("Nome do questionário é obrigatório."),

	questions: Yup.array()
		.of(questionValidationSchema)
		.min(1, "Questionários devem ter pelo menos uma pergunta.")
		.required("Questionários devem ter pelo menos uma pergunta."),

	results: Yup.array().of(
		Yup.object().shape({
			title: Yup.string().required("Os resultados devem ter um título."),
			description: Yup.string().required(
				"Os resultados devem ter uma descrição.",
			),
			minimumValue: Yup.number()
				.typeError(
					"Os resultados devem ter um valor mínimo para atingir.",
				)
				.required(
					"Os resultados devem ter um valor mínimo para atingir.",
				)
				.min(
					0,
					"O valor mínimo para atingir um resultado deve ser maior ou igual a zero.",
				),
		}),
	),

	imcValues: Yup.array()
		.of(
			Yup.object().shape({
				imc: Yup.number()
					.typeError("O IMC deve ser um número válido.")
					.required("O IMC é obrigatório.")
					.min(0, "O IMC deve ser positivo."),
				value: Yup.number()
					.typeError("O valor deve ser um número válido.")
					.required("Valor obrigatório.")
					.min(0, "O valor deve ser positivo."),
			}),
		)
		.nullable()
		.optional(),
});
