// MARK: Material
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { green, red } from "@material-ui/core/colors";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			width: "100%",
			maxWidth: 800,
			marginTop: 3,
			margin: 0,
			marginRight: "auto",
			marginLeft: "auto",
		},
		card: {
			display: "flex",
			width: "100%",
			maxWidth: 800,
			marginTop: 5,
			marginRight: "auto",
			marginLeft: "auto",
			marginBottom: theme.spacing(1),
			backgroundColor:
				theme.palette.type === "dark"
					? theme.palette.grey[600]
					: theme.palette.grey[200],
			borderRadius: 15,
			boxShadow: "none",
			padding: 5,
		},
		details: {
			display: "flex",
			width: "100%",
			padding: 0,
		},
		content: {
			width: "100%",
			height: 90,
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			position: "relative",
			padding: 0,
		},
		contentTexts: {
			width: "100%",
			height: "100%",
			position: "absolute",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			zIndex: 80,
		},
		texts: {
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
		},
		Iconscontent: {
			position: "absolute",
			bottom: 0,
			right: -1,
			zIndex: 100,
			marginTop: 1,
		},
		cover: {
			alignSelf: "center",
			minHeight: 80,
			minWidth: 80,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			overflow: "hidden",
			marginRight: 5,
			marginLeft: 5,
			position: "relative",
			borderRadius: 10,
		},
		FloatIcon: {
			zIndex: 100,
			position: "absolute",
			top: -10,
			right: -10,
		},
		CardImage: {
			height: "100%",
			zIndex: 60,
		},

		darkOverlay: {
			zIndex: 80,
			position: "absolute",
			right: 0,
			left: 0,
			bottom: 0,
			top: 0,
			boxSizing: "border-box",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "#bdbdbd",
			opacity: 0.6,
		},
		redOverlay: {
			zIndex: 80,
			position: "absolute",
			right: 0,
			left: 0,
			bottom: 0,
			top: 0,
			boxSizing: "border-box",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "#d30000dc",
			opacity: 0.6,
		},
		controls: {
			display: "flex",
			alignItems: "center",
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		checkIcon: {
			color: green[500],
		},
		failedIcon: {
			color: red[500],
		},
		disabledActions: {
			display: "none",
		},

	}),
);

export default useStyles;
