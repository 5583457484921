import React from "react";
import useStyles from "./styles";
import {
	Box,
	Dialog,
	DialogContent,
	Divider,
	Button,
	Typography,
	TextField,
	TableRow,
	TableCell,
	IconButton,
	Tooltip,
} from "@material-ui/core";
import { Delete, Add as AddIcon } from "@material-ui/icons";
import { observer, useLocalStore } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import API from "../../../../../modules/API";
import CardForm from "../../../../../components/CardForm";
import SimpleTable from "../../../../../components/SimpleTable";
import { useGlobalStore } from "../../../../context";
import { questionValidationSchema } from "./validation";
import Store from "./store";

interface IProps {
	isVisible: boolean;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	addQuestionToQuiz: (question: API.NewQuestion) => void;
}

// tslint:disable-next-line:max-func-body-length
const CreateQuestionDialog: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));
	const {
		control,
		handleSubmit,
		unregister,
		formState,
	} = useForm<API.NewQuestion>({
		mode: "onChange",
		resolver: yupResolver(questionValidationSchema),
	});

	const closeDialog = () => {
		props.setIsVisible(false);
	};

	const onSubmit = (data: API.NewQuestion) => {
		props.addQuestionToQuiz(data);
		closeDialog();
	};

	const optionsDescriptionError = (index: number) => {
		if (formState.errors.options) {
			const optionsErrors = formState.errors.options;
			return {
				hasError: !!optionsErrors[index]?.description,
				errorMessage: optionsErrors[index]?.description?.message,
			};
		}

		return undefined;
	};

	const optionsValueError = (index: number) => {
		if (formState.errors.options) {
			const optionsErrors = formState.errors.options;
			return {
				hasError: !!optionsErrors[index]?.value,
				errorMessage: optionsErrors[index]?.value?.message,
			};
		}

		return undefined;
	};

	return (
		<Dialog open={props.isVisible} maxWidth="lg" fullWidth={true}>
			<DialogContent>
				<CardForm
					title="Adicionar pergunta"
					backButtonDisabled={true}
					actionsFooter={
						<>
							<Button onClick={closeDialog}>Cancelar</Button>
							<Button
								onClick={() => {
									if (formState.errors) {
										console.log(formState.errors);
									}
									handleSubmit(onSubmit)();
								}}
							>
								Salvar
							</Button>
						</>
					}
				>
					<Controller
						name={`description`}
						control={control}
						defaultValue=""
						render={({ field }) => (
							<Tooltip
								title="Texto da pergunta. Exemplo: Quantas vezes por semana você pratica exercício físico?"
								placement="bottom"
							>
								<TextField
									variant="standard"
									type="text"
									label="Texto da pergunta"
									{...field}
									error={!!formState.errors.description}
									helperText={
										formState.errors.description?.message ||
										undefined
									}
								/>
							</Tooltip>
						)}
					/>

					<Divider className={classes.divider} />
					<Typography variant="h6">Opções de resposta</Typography>
					<SimpleTable
						rows={store.answers}
						headers={["Dados da resposta", "Deletar"]}
						isSmallContainer={true}
						renderItem={(item, index) => (
							<TableRow hover={true} key={index}>
								<TableCell align="center">
									<Box className={classes.inputRowBox}>
										<Controller
											name={
												`options[${index}].description` as `options.${number}.description`
											}
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													label="Texto da resposta"
													className={
														classes.titleField
													}
													{...field}
													error={
														!!optionsDescriptionError(
															index,
														)?.hasError
													}
													helperText={
														optionsDescriptionError(
															index,
														)?.errorMessage
													}
												/>
											)}
										/>

										<Controller
											name={
												`options[${index}].value` as `options.${number}.value`
											}
											control={control}
											defaultValue=""
											render={({ field }) => (
												<TextField
													label="Valor"
													type="number"
													className={
														classes.valueField
													}
													{...field}
													error={
														!!optionsValueError(
															index,
														)?.hasError
													}
													helperText={
														optionsValueError(index)
															?.errorMessage
													}
												/>
											)}
										/>
									</Box>
								</TableCell>
								<TableCell align={"center"}>
									<IconButton
										onClick={() => {
											store.removeAnswer(index);
											unregister([
												`options[${index}].description` as `options.${number}.description`,
												`options[${index}].value` as `options.${number}.value`,
											]);
										}}
									>
										<Delete />
									</IconButton>
								</TableCell>
							</TableRow>
						)}
						bottomToolbar={
							<Button
								startIcon={<AddIcon />}
								onClick={store.addBlankQuestion}
							>
								Adicionar resposta
							</Button>
						}
					/>
				</CardForm>
			</DialogContent>
		</Dialog>
	);
};

export default observer(CreateQuestionDialog);
