import API from "../../../../modules/API";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";
import Strings from "../../../../modules/Strings";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditAdminUser {
	id: string;
	name: string;
	email: string;
}

export default class Store extends ModelStore<IEditAdminUser | API.AdminUser> {
	public adminUserImageService: FileStore | null = null;

	protected router: IRouteReplacer;

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		this.adminUserImageService = new FileStore(uiStore, "image");
	}

	public formController = new FormStore({
		name: "",
		email: "",
	});

	protected getModel(id: string) {
		return API.getAdminUser(id);
	}

	protected afterModelFetch(model: IEditAdminUser) {
		this.adminUserImageService = new FileStore(this.uiStore, "image");
		this.formController = new FormStore({
			name: model.name || "",
			email: model.email || "",
		});
	}

	public editAdminUser = async (succesMessage: string) => {
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}

			const { email, name } = this.formController.getValues();

			const adminUser = await API.editAdminUser(this.id, {
				email,
				name,
			});

			localStorage.setItem("adminUser", JSON.stringify(adminUser));
			this.router.replace("/dashboard/adminUsers");
			this.router.go(0);
			this.uiStore.showSnackbar(
				Strings.adminUsers.edit.success(adminUser.name),
			);
		} catch (error) {
			this.error = error.message;
			this.uiStore.showErrorSnackbar(this.error);
		} finally {
			this.loading = false;
		}
	};
}
