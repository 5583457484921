import React from "react";
import { useHistory } from "react-router";
import { Detector } from "react-detect-offline";
import { observer } from "mobx-react-lite";
import { Menu, ExitToApp } from "@material-ui/icons";
import {
	AppBar,
	Button,
	IconButton,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Strings from "../../../modules/Strings";
import { useGlobalStore } from "../../../pages/context";

interface IProps {
	toggleDrawer?: () => void;
}

const Navbar: React.FC<IProps> = (props) => {
	const { authStore } = useGlobalStore();
	const history = useHistory();

	const pushToLogin = () => {
		history.replace("/login");
	};

	return (
		<>
			<AppBar
				elevation={1}
				position="fixed"
				style={{ backgroundColor: "#ffff" }}
			>
				<Toolbar>
					<IconButton
						color="secondary"
						edge="start"
						aria-label="Menu"
						onClick={props.toggleDrawer}
					>
						<Menu color="secondary" />
					</IconButton>
					<Typography color="secondary" variant="h5">
						{Strings.nav.title}
					</Typography>
					<div style={{ flexGrow: 1 }} />
					<Button
						startIcon={<ExitToApp color="secondary" />}
						onClick={() => {
							authStore.logout(pushToLogin);
						}}
					>
						{Strings.fields.exit}
					</Button>
				</Toolbar>
				<Detector
					// tslint:disable-next-line: jsx-alignment
					render={({ online }) =>
						!online && (
							<Alert
								style={{ borderRadius: 0 }}
								variant="filled"
								severity="warning"
							>
								{Strings.nav.disconected}
							</Alert>
						)
					}
				/>
			</AppBar>
		</>
	);
};

export default observer(Navbar);
