import React from "react";
import "./style.scss";

import { CardHeader, Divider, Typography } from "@material-ui/core";

interface IProps {
	title: string;
}

export const SimpleHeader = (props: IProps) => {
	return (
		<React.Fragment>
			<CardHeader
				className="rowWidth"
				title={<Typography variant={"h6"}>{props.title}</Typography>}
			/>
			<Divider />
		</React.Fragment>
	);
};
