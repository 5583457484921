import { makeObservable, observable, runInAction } from "mobx";
import API from "../../../../modules/API";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

export default class Store extends ModelStore<API.Result> {
	public result: API.Result | null = null;

	constructor(id: string, uiStore: UIStore) {
		super(id, uiStore);
		this.getResult(id);
		makeObservable(this, {
			result: observable,
		});
	}

	protected async getModel(id: string) {
		return await API.getResultById(id);
	}

	public getResult = async (id: string) => {
		const result = await API.getResultById(id);
		runInAction(() => (this.result = result));
	};
}
