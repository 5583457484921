import {
	runInAction,
	makeAutoObservable,
	makeObservable,
	observable,
} from "mobx";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import UIStore from "../../../../stores/UIStore";
import ModelStore from "../../../../stores/ModelStore";

export interface IRequest {
	name: string;
	email: string;
	password: string;
}

interface IPush {
	goBack: () => void;
}

export default class Store {
	public uiStore: UIStore;
	private history: IPush;

	public quiz: API.Quiz | null = null;

	public questions: API.NewQuestion[] = [];

	public hasImcValues: boolean = false;

	public getQuiz = async (id: string) => {
		const quiz = await API.findQuizById(id);
		runInAction(() => {
			this.quiz = quiz;
			this.questions = quiz.questions;
			this.results = quiz.results;
			if (quiz.imcValues && quiz.imcValues.length > 0) {
				this.hasImcValues = true;
				this.imcValues = quiz.imcValues;
			}
		});
	};

	public addBlankImcValue = () => {
		if (this.imcValues === null) {
			this.imcValues = [{ imc: 0, value: 0 }];
		}
		this.imcValues.push({ imc: 0, value: 0 });
	};

	public removeImcValue = (imcValueIndex: number) => {
		if (this.imcValues !== null) {
			if (this.imcValues.length > 1) {
				this.imcValues = this.imcValues.filter(
					(_, index) => index !== imcValueIndex,
				);
			} else {
				this.uiStore.showSnackbar(
					"Desabilite o peso do IMC no questionário.",
				);
			}
		}
	};

	public imcValues: API.NewImcValue[] | null = null;

	public onChangeHasImcValues = () => {
		if (this.hasImcValues) {
			this.imcValues = null;
		} else {
			this.imcValues = this.quiz?.imcValues
				? this.quiz.imcValues
				: [{ imc: 0, value: 0 }];
		}
		this.hasImcValues = !this.hasImcValues;
	};

	public addQuestionToQuiz = (question: API.NewQuestion) => {
		this.questions.push(question);
	};

	public deleteQuestion = (questionIndex: number) => {
		this.questions = this.questions.filter(
			(_, index) => index !== questionIndex,
		);
	};

	public results: API.NewQuizResult[] = [
		{ title: "", minimumValue: 0, description: "" },
	];

	public addBlankResult = () => {
		this.results.push({ title: "", minimumValue: 0, description: "" });
	};

	public removeResult = (resultIndex: number) => {
		if (this.results.length > 1) {
			this.results = this.results.filter(
				(_, index) => index !== resultIndex,
			);
		} else {
			this.uiStore.showSnackbar(
				"Questionários devem ter pelo menos um possível resultado com pontuação mínima 0.",
			);
		}
	};

	public loading = false;
	public error: string | null = null;

	constructor(id: string, uiStore: UIStore, history: IPush) {
		makeAutoObservable(this);
		this.uiStore = uiStore;
		this.history = history;
		this.getQuiz(id);
	}

	public editQuiz = async (quizData: API.NewQuiz) => {
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			if (!this.quiz) {
				throw new Error("Nenhum quiz selecionado");
			}

			runInAction(() => (this.loading = true));
			await API.editQuiz(this.quiz.id, quizData);
			this.history.goBack();
			this.uiStore.showSnackbar(Strings.quizz.edit.success);
		} catch (error) {
			runInAction(() => (this.error = Errors.handleError(error)));
			this.uiStore.showErrorSnackbar(this.error);
		} finally {
			runInAction(() => (this.loading = false));
		}
	};
}
