import React from "react";
import useStyles from "./styles";
import {
	Dialog,
	DialogContent,
	Button,
	Typography,
	List,
	ListItem,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import CardForm from "../../../../components/CardForm";

interface IProps {
	isVisible: boolean;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImcHelpDialog: React.FC<IProps> = (props) => {
	const classes = useStyles();

	const closeDialog = () => {
		props.setIsVisible(false);
	};

	return (
		<Dialog open={props.isVisible} maxWidth="lg" fullWidth={true}>
			<DialogContent>
				<CardForm
					title="Ajuda sobre pontuação do IMC no questionário"
					backButtonDisabled={true}
					actionsFooter={
						<>
							<Button onClick={closeDialog}>Fechar</Button>
						</>
					}
				>
					<Typography variant="body1">
						Cadastre valores mínimos de IMC necessários para que se
						some determinada pontuação ao resultado. Dessa forma, as
						faixas de pontuação por IMC serão criadas.
						<br />
						<br />
						Isso é opcional. Você pode desabilitar o efeito do IMC
						no resultado caso não seja relevante.
						<br />
						<br />
						Caso o usuário não tenha informado peso e altura, e
						portanto não tenha o IMC cadastrado, não serão somados
						pontos para cálculo do resultado.
						<br />
						<br />
					</Typography>

					<Typography variant="body1" className={classes.boldText}>
						Por exemplo:
					</Typography>

					<List>
						<ListItem>
							<Typography variant="body1">
								IMC 1 - IMC mínimo: 20 - Valor: 10.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								IMC 2 - IMC mínimo: 25 - Valor: 20.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								IMC 3 - IMC mínimo: 30 - Valor: 30.
							</Typography>
						</ListItem>
					</List>

					<Typography variant="body1" className={classes.boldText}>
						<br />
						Como isso irá impactar no cálculo do resultado?
					</Typography>

					<List>
						<ListItem>
							<Typography variant="body1">
								Com IMC inferior a 20, não serão somados pontos
								ao resultado.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Com IMC de 20 ou mais e menos de 25, serão
								somados 10 pontos.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Com IMC de 25 ou mais e menos de 30, serão
								somados 20 pontos.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant="body1">
								Com IMC de 30 ou mais, serão somados 30 pontos.
							</Typography>
						</ListItem>
					</List>
				</CardForm>
			</DialogContent>
		</Dialog>
	);
};

export default observer(ImcHelpDialog);
