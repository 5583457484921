import React from "react";
import useStyles from "./styles";
import { observer } from "mobx-react-lite";
import {
	Button,
	TextField,
	TableRow,
	TableCell,
	IconButton,
	Box,
	Typography,
} from "@material-ui/core";
import API from "../../../../../modules/API";
import { Delete, Add as AddIcon, Help } from "@material-ui/icons";
import SimpleTable from "../../../../../components/SimpleTable";
import Store from "../store";
import {
	Controller,
	Control,
	UseFormUnregister,
	FormState,
} from "react-hook-form";

interface IProps {
	store: Store;
	control: Control<API.NewQuiz>;
	formState: FormState<API.NewQuiz>;
	unregister: UseFormUnregister<API.NewQuiz>;
	setIsHelpVisibe: React.Dispatch<React.SetStateAction<boolean>>;
}

type ResultField = "title" | "description" | "minimumValue";

const resultsError = (
	formState: FormState<API.NewQuiz>,
	field: ResultField,
	index: number,
) => {
	if (formState.errors.results) {
		const resultsErrors = formState.errors.results;
		return {
			hasError: !!resultsErrors[index]?.[field],
			errorMessage: resultsErrors[index]?.[field]?.message,
		};
	}

	return undefined;
};

// tslint:disable-next-line:max-func-body-length
const ResultsTable: React.FC<IProps> = (props) => {
	const classes = useStyles();

	return (
		<SimpleTable
			title="Opções de resultado"
			rows={props.store.results}
			headers={["Dados do resultado", "Deletar"]}
			isSmallContainer={true}
			renderItem={(item, index) => (
				<TableRow hover={true} key={index}>
					<TableCell align="center">
						<Box className={classes.inputRowBoxWithMargin}>
							<Controller
								name={
									`results[${index}].title` as `results.${number}.title`
								}
								control={props.control}
								defaultValue={props.store.results[index].title}
								render={({ field }) => (
									<TextField
										label="Título do resultado"
										className={classes.titleField}
										{...field}
										error={
											!!resultsError(
												props.formState,
												"title",
												index,
											)?.hasError
										}
										helperText={
											resultsError(
												props.formState,
												"title",
												index,
											)?.errorMessage
										}
									/>
								)}
							/>

							<Controller
								name={
									`results[${index}].minimumValue` as `results.${number}.minimumValue`
								}
								control={props.control}
								defaultValue={
									index === 0
										? 0
										: props.store.results[index]
												.minimumValue
								}
								render={({ field }) => (
									<TextField
										label="Valor mínimo"
										className={classes.valueField}
										{...(index === 0 ? { value: 0 } : {})}
										{...(index === 0
											? { disabled: true }
											: {})}
										type="number"
										{...field}
										error={
											!!resultsError(
												props.formState,
												"minimumValue",
												index,
											)?.hasError
										}
										helperText={
											resultsError(
												props.formState,
												"minimumValue",
												index,
											)?.errorMessage
										}
									/>
								)}
							/>
						</Box>
						<Box className={classes.inputRowBox}>
							<Controller
								name={
									`results[${index}].description` as `results.${number}.description`
								}
								control={props.control}
								defaultValue={
									props.store.results[index].description
								}
								render={({ field }) => (
									<TextField
										label="Descrição e recomendações"
										className={classes.descriptionField}
										multiline={true}
										{...field}
										error={
											!!resultsError(
												props.formState,
												"description",
												index,
											)?.hasError
										}
										helperText={
											resultsError(
												props.formState,
												"description",
												index,
											)?.errorMessage
										}
									/>
								)}
							/>
						</Box>
					</TableCell>
					<TableCell align={"center"}>
						<IconButton
							onClick={() => {
								props.store.removeResult(index);
								props.unregister([
									`results[${index}].title` as `results.${number}.title`,
									`results[${index}].minimumValue` as `results.${number}.minimumValue`,
									`results[${index}].description` as `results.${number}.description`,
								]);
							}}
						>
							<Delete />
						</IconButton>
					</TableCell>
				</TableRow>
			)}
			bottomToolbar={
				<>
					<Button
						startIcon={<AddIcon />}
						onClick={props.store.addBlankResult}
					>
						Adicionar resultado
					</Button>

					<Button
						startIcon={<Help />}
						onClick={() => {
							props.setIsHelpVisibe(true);
						}}
						className={classes.helpButton}
					>
						Ajuda sobre resultados
					</Button>
				</>
			}
		/>
	);
};

export default observer(ResultsTable);
