import { makeAutoObservable, runInAction } from "mobx";
import API from "../../modules/API";
import UIStore from "../UIStore";
import FormStore from "../FormStore";
import Strings from "../../modules/Strings";
import { Errors } from "../../modules/Errors";

export default class AuthStore {
	private uiStore: UIStore;

	public adminUser: API.AdminUser | null = null;
	public loading: boolean = false;
	public receivedToken: string = "";
	public formController = new FormStore({
		email: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
	});

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.updateUser();
		makeAutoObservable(this);
	}

	public isLoginFormReady() {
		const data = this.formController.getValues();
		return data.email.length > 5 && data.password.length >= 6;
	}

	private updateUser = () => {
		const adminUser = localStorage.getItem("adminUser");
		runInAction(
			() =>
				(this.adminUser = adminUser
					? JSON.parse(localStorage.getItem("adminUser") as string)
					: null),
		);
	};

	public login = async (onSuccess?: () => void) => {
		const data = this.formController.getValues();
		runInAction(() => (this.loading = true));

		try {
			const resultAdminUser = await API.login(
				data.email.trim(),
				data.password.trim(),
			);

			runInAction(() => (this.adminUser = resultAdminUser));

			localStorage.setItem("adminUser", JSON.stringify(this.adminUser));

			if (onSuccess) {
				onSuccess();
			}

			if ("Notification" in window) {
				if (Notification.permission === "granted") {
					const notification = new Notification(Strings.hiAdmin);
				}
			}

			this.clear();
		} catch (e) {
			console.log(e);
			this.uiStore.showSnackbar(Errors.handleError(e));
		} finally {
			runInAction(() => (this.loading = false));
		}
	};

	public logout = async (onSuccess?: () => void) => {
		this.uiStore.showDialog(Strings.logoutDialog, async () => {
			try {
				await API.logout();
				this.adminUser = null;
				localStorage.clear();
				if (onSuccess) {
					onSuccess();
				}
			} catch (e) {
				this.uiStore.showErrorSnackbar(e);
			}
		});
	};

	public isLogged = async () => {
		runInAction(() => (this.loading = true));

		try {
			const resultAdminUser = await API.getCurrentAdminUser();
			runInAction(() => (this.adminUser = resultAdminUser));
		} catch (e) {
			this.uiStore.showSnackbar(Errors.handleError(e));
			localStorage.clear();
		} finally {
			runInAction(() => (this.loading = false));
		}

		return !!this.adminUser;
	};

	public authenticate = async (
		onSuccess: () => void = () => {},
		onFail: () => void = () => {},
	) => {
		if (!(await this.isLogged())) {
			onFail();
		} else {
			onSuccess();
		}
	};

	public setToken = (token: string) => {
		runInAction(() => (this.receivedToken = token));
	};

	private clear = () => {
		const data = this.formController.getValues();
		runInAction(() => {
			data.email = "";
			data.password = "";
		});
	};
}
