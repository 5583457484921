import React from "react";
import useStyles from "./styles";
import {
	Box,
	Dialog,
	DialogContent,
	Divider,
	Button,
	Typography,
	TextField,
	TableRow,
	TableCell,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import API from "../../../../../modules/API";
import CardForm from "../../../../../components/CardForm";
import SimpleTable from "../../../../../components/SimpleTable";

interface IProps {
	question: API.NewQuestion;
	setSelectedQuestionIndex: React.Dispatch<
		React.SetStateAction<number | undefined>
	>;
}

const QuestionDetailsDialog: React.FC<IProps> = (props) => {
	const classes = useStyles();

	const closeDialog = () => {
		props.setSelectedQuestionIndex(undefined);
	};

	return (
		<Dialog open={true} maxWidth="lg" fullWidth={true}>
			<DialogContent>
				<CardForm
					title="Detalhas da pergunta"
					backButtonDisabled={true}
					actionsFooter={
						<>
							<Button onClick={closeDialog}>Fechar</Button>
						</>
					}
				>
					<TextField
						variant="standard"
						type="text"
						disabled={true}
						value={props.question.description}
					/>
					<Divider className={classes.divider} />
					<Typography variant="h6">Opções de resposta</Typography>
					<SimpleTable
						rows={props.question.options}
						headers={["Dados da resposta"]}
						isSmallContainer={true}
						renderItem={(item, index) => (
							<TableRow hover={true} key={index}>
								<TableCell align="center">
									<Box className={classes.inputRowBox}>
										<TextField
											label="Texto da resposta"
											disabled={true}
											className={classes.titleField}
											value={item.description}
										/>

										<TextField
											label="Valor"
											disabled={true}
											className={classes.valueField}
											value={item.value}
										/>
									</Box>
								</TableCell>
							</TableRow>
						)}
					/>
				</CardForm>
			</DialogContent>
		</Dialog>
	);
};

export default observer(QuestionDetailsDialog);
