import React from "react";
import useStyles from "../styles";
import { Card, CardContent, Box, TextField } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import API from "../../../../../modules/API";

interface IProps {
	answer: API.Answer;
}

const AnswerCard: React.FC<IProps> = (props) => {
	const { answer } = props;

	const roundOneDecimal = (
		value: number | null | undefined,
	): number | null => {
		if (!value) {
			return null;
		}

		return Math.round(value * 10) / 10;
	};

	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<CardContent>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Pergunta"
						value={answer.questionDescription || ""}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Resposta"
						value={answer.selectedOption.description || ""}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Valor"
						value={
							roundOneDecimal(answer.selectedOption.value) || 0
						}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
			</CardContent>
		</Card>
	);
};

export default observer(AnswerCard);
