import api from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class AdminTableStore extends PaginatedListStore<api.AdminActivity> {
	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
		this.uiStore = uiStore;
	}

	protected getDataItemsPerPage(page: number): Promise<api.AdminActivity[]> {
		return api.getAdminActivitiesPaginated(page);
	}
}
