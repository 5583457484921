import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	root: {},
	box: {
		minHeight: "calc(70vh - 70px)",
	},
	cardContent: {
		display: "flex",
		flexDirection: "column",
	},
}));
