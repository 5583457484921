// MARK: React
import React from "react";

// MARK: Context
import { useGlobalStore } from "../../pages/context";

// MARK: Components
import { Snackbar as MSnackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";

// MARK: Styles
import { useStyles } from "./styles";

interface IProps {
	snackbarMessage?: string | null;
	typeMessage?: Color | null;
}

const Snackbar: React.FC<IProps> = ({ snackbarMessage, typeMessage }) => {
	const classes = useStyles();
	const { themeStore } = useGlobalStore();

	return (
		<MSnackbar
			className={classes.root}
			open={!!snackbarMessage}
			ContentProps={{ "aria-describedby": "Alert" }}
		>
			<Alert
				className={classes.alert}
				variant={themeStore.isDark ? "standard" : "filled"}
				severity={typeMessage ? typeMessage : "info"}
			>
				{snackbarMessage}
			</Alert>
		</MSnackbar>
	);
};

export default Snackbar;
