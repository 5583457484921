import React from "react";
import useStyles from "./styles";
import {
	Button,
	TableRow,
	TableCell,
	IconButton,
	Typography,
} from "@material-ui/core";
import { Delete, Edit, Visibility, Add as AddIcon } from "@material-ui/icons";
import SimpleTable from "../../../../../components/SimpleTable";
import Store from "../store";
import { observer } from "mobx-react-lite";

interface IProps {
	store: Store;
	error: boolean;
	setSelectedQuestionIndex: React.Dispatch<
		React.SetStateAction<number | undefined>
	>;
	openAddQuestionModal: () => void;
}

const QuestionsTable: React.FC<IProps> = (props) => {
	const classes = useStyles();
	return (
		<SimpleTable
			title="Perguntas"
			rows={props.store.questions}
			headers={[
				"Pergunta",
				// Strings.components.table.detailsAndEditAndDelete,
				"Detalhes / Deletar",
			]}
			isSmallContainer={true}
			renderItem={(item, index) => (
				<TableRow hover={true} key={index}>
					<TableCell align={"center"}>{item.description}</TableCell>
					<TableCell align={"center"}>
						{/* <IconButton onClick={() => {}}>
							<Edit />
						</IconButton> */}
						<IconButton
							onClick={() => {
								props.setSelectedQuestionIndex(index);
							}}
						>
							<Visibility />
						</IconButton>
						<IconButton
							onClick={() => {
								props.store.deleteQuestion(index);
							}}
						>
							<Delete />
						</IconButton>
					</TableCell>
				</TableRow>
			)}
			fallback={
				<Typography
					variant="body1"
					color={props.error ? "error" : undefined}
					className={classes.fallbackText}
				>
					Adicione perguntas ao seu questionário!
				</Typography>
			}
			bottomToolbar={
				<Button
					startIcon={<AddIcon />}
					onClick={props.openAddQuestionModal}
				>
					Adicionar pergunta
				</Button>
			}
		/>
	);
};

export default observer(QuestionsTable);
