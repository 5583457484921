import jsonexport from "jsonexport/dist";
import moment from "moment";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";

interface IResultCsvFormat extends Record<string, any> {
	id_resultado: string;
	nome: string;
	data_nasc: string | null;
	altura: number | null;
	peso: number | null;
	imc: number | null;
	email: string | null;
	telefone: string | null;
	questionario: string;
	data_resposta: string;
	resultado: string;
	pontos_necessarios_result: number;
	pontos_perguntas: number;
	pontos_imc: number;
	pontos_total: number;
}

const formatDate = (date: Date) => {
	return moment(date).format(Strings.moment.date);
};

export default class ResultsTableStore extends PaginatedListStore<API.Result> {
	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
		this.uiStore = uiStore;
	}

	private convertResultsToCsvFormat = (
		results: API.Result[],
	): IResultCsvFormat[] => {
		return results.map((result) => {
			const birthdate = result.user.birthdate;

			const resultConverted = {
				id_resultado: result.id,
				nome: result.user.name,
				data_nasc: birthdate ? formatDate(birthdate) : null,
				altura: result.user.height,
				peso: result.user.weight,
				imc: result.user.imc,
				email: result.user.email,
				telefone: result.user.phone,
				questionario: result.quizName,
				data_resposta: formatDate(result.editedAt),
				resultado: result.quizResult.title,
				pontos_necessarios_result: result.quizResult.minimumValue,
				pontos_perguntas: result.questionsPoints,
				pontos_imc: result.imcPoints,
				pontos_total: result.totalPoints,
			};

			result.answers.forEach((answer, index) => {
				resultConverted[`${index + 1}_pergunta`] =
					answer.questionDescription;
				resultConverted[`${index + 1}_resposta`] =
					answer.selectedOption.description;
				resultConverted[`${index + 1}_pontos`] =
					answer.selectedOption.value;
			});

			return resultConverted;
		});
	};

	private downloadResultsCsv = async (
		resultsCsvFormat: IResultCsvFormat[],
		fileName: string,
	) => {
		try {
			const csv = await jsonexport(resultsCsvFormat);
			const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

			if (navigator.msSaveBlob) {
				// IE 10+
				navigator.msSaveBlob(blob, fileName);
			} else {
				const link = document.createElement("a");
				if (link.download !== undefined) {
					// Feature detection
					// Browsers that support HTML5 download attribute
					const url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", fileName);
					link.style.visibility = "hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	public downloadCurrentPageResultsCsv = async () => {
		if (this._loading || this._items.length === 0) {
			this.uiStore.showSnackbar("Não há resultados para exportar");
			return;
		}

		const resultsConverted = this.convertResultsToCsvFormat(this._items);

		const fileName = `drDiabetes_resultados_pag${
			this.page() + 1
		}_${Date.now()}.csv`;

		this.downloadResultsCsv(resultsConverted, fileName);
	};

	public downloadAllResultsCsv = async () => {
		try {
			const results = await API.findAllResults();

			const resultsConverted = this.convertResultsToCsvFormat(results);

			const fileName = `drDiabetes_resultados_${Date.now()}.csv`;

			this.downloadResultsCsv(resultsConverted, fileName);
		} catch (error) {
			console.error(error);
		}
	};

	protected getDataItemsPerPage(page: number): Promise<API.Result[]> {
		return API.findAllResultsPaginated(page);
	}
}
