import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface File {
    name: string;
    url: string;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface ImageCrop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface UploadFile {
    bytes: Buffer;
    name: string;
}

export interface UserDetails {
    name: string;
    birthdate: Date | null;
    weight: number | null;
    height: number | null;
    imc: number | null;
    sex: UserDetailsSex | null;
    email: string | null;
    phone: string | null;
    image: Image | null;
}

export interface AdminUserDetails {
    name: string;
    email: string;
}

export interface NewAdminUser {
    password: string;
    name: string;
    email: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
}

export interface AdminUser {
    id: string;
    name: string;
    email: string;
}

export interface NewQuestionOption {
    description: string;
    value: number;
}

export interface NewQuestion {
    description: string;
    options: NewQuestionOption[];
}

export interface NewImcValue {
    imc: number;
    value: number;
}

export interface NewQuizResult {
    title: string;
    description: string;
    minimumValue: number;
}

export interface NewQuiz {
    name: string;
    questions: NewQuestion[];
    imcValues: NewImcValue[] | null;
    results: NewQuizResult[];
}

export interface QuestionOption {
    id: string;
    description: string;
    value: number;
}

export interface Question {
    id: string;
    description: string;
    options: QuestionOption[];
}

export interface ImcValue {
    id: string;
    imc: number;
    value: number;
}

export interface QuizResult {
    id: string;
    title: string;
    description: string;
    minimumValue: number;
}

export interface Quiz {
    id: string;
    name: string;
    questions: Question[];
    imcValues: ImcValue[] | null;
    results: QuizResult[];
    active: boolean;
    createdAt: Date;
    editedAt: Date;
}

export interface User {
    id: string;
    createdAt: Date;
    editedAt: Date;
    name: string;
    birthdate: Date | null;
    weight: number | null;
    height: number | null;
    imc: number | null;
    sex: UserDetailsSex | null;
    email: string | null;
    phone: string | null;
    image: Image | null;
}

export interface ResultUser {
    id: string;
    createdAt: Date;
    name: string;
    birthdate: Date | null;
    weight: number | null;
    height: number | null;
    imc: number | null;
    sex: UserDetailsSex | null;
    email: string | null;
    phone: string | null;
    image: Image | null;
}

export interface Answer {
    questionDescription: string;
    selectedOption: QuestionOption;
}

export interface Result {
    id: string;
    quizId: string;
    quizName: string;
    user: ResultUser;
    imcPoints: number;
    questionsPoints: number;
    totalPoints: number;
    quizResult: QuizResult;
    answers: Answer[];
    createdAt: Date;
    editedAt: Date;
}

export interface AdminActivity {
    id: string;
    adminId: string;
    activityDescription: string;
    createdAt: Date;
    editedAt: Date;
}

export enum Language {
    ptBr = "ptBr",
    enUs = "enUs",
}

export function translateLanguage(enumLanguage: Language): string {
    switch (enumLanguage) {
        case Language.ptBr: {
            return strings ? strings["enum"]["Language"]["ptBr"] || Language.ptBr : Language.ptBr;
        }
        case Language.enUs: {
            return strings ? strings["enum"]["Language"]["enUs"] || Language.enUs : Language.enUs;
        }
    }
    return "";
}

export function allValuesLanguage(): Language[] {
    return [
        Language.ptBr,
        Language.enUs,
    ];
}

export function allTranslatedValuesLanguage(): string[] {
    return [
        translateLanguage(Language.ptBr),
        translateLanguage(Language.enUs),
    ];
}

export function allDisplayableValuesLanguage(): string[] {
    return allTranslatedValuesLanguage().sort();
}

export function valueFromTranslationLanguage(translation: string): Language {
    const index = allTranslatedValuesLanguage().indexOf(translation);
    return allValuesLanguage()[index] || Language.ptBr;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum UserDetailsSex {
    male = "male",
    female = "female",
}

export function translateUserDetailsSex(enumUserDetailsSex: UserDetailsSex): string {
    switch (enumUserDetailsSex) {
        case UserDetailsSex.male: {
            return strings ? strings["enum"]["UserDetailsSex"]["male"] || UserDetailsSex.male : UserDetailsSex.male;
        }
        case UserDetailsSex.female: {
            return strings ? strings["enum"]["UserDetailsSex"]["female"] || UserDetailsSex.female : UserDetailsSex.female;
        }
    }
    return "";
}

export function allValuesUserDetailsSex(): UserDetailsSex[] {
    return [
        UserDetailsSex.male,
        UserDetailsSex.female,
    ];
}

export function allTranslatedValuesUserDetailsSex(): string[] {
    return [
        translateUserDetailsSex(UserDetailsSex.male),
        translateUserDetailsSex(UserDetailsSex.female),
    ];
}

export function allDisplayableValuesUserDetailsSex(): string[] {
    return allTranslatedValuesUserDetailsSex().sort();
}

export function valueFromTranslationUserDetailsSex(translation: string): UserDetailsSex {
    const index = allTranslatedValuesUserDetailsSex().indexOf(translation);
    return allValuesUserDetailsSex()[index] || UserDetailsSex.male;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    BadFormattedResponse = "BadFormattedResponse",
    ActionNotAllowed = "ActionNotAllowed",
    InvalidDate = "InvalidDate",
    FailedUpload = "FailedUpload",
    MissingEnvVariable = "MissingEnvVariable",
    InternalError = "InternalError",
    FieldAlreadyInUse = "FieldAlreadyInUse",
    WrongPassword = "WrongPassword",
    EmptyArray = "EmptyArray",
    NotApproved = "NotApproved",
    NotLoggedIn = "NotLoggedIn",
    UserBlocked = "UserBlocked",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    EmailAlreadyInUse = "EmailAlreadyInUse",
    InvalidEmail = "InvalidEmail",
    ExpiredResetPasswordToken = "ExpiredResetPasswordToken",
    LoginError = "LoginError",
    UserDoesntExist = "UserDoesntExist",
    AlreadyRegistered = "AlreadyRegistered",
    CpfAlreadyInUse = "CpfAlreadyInUse",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.ActionNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["ActionNotAllowed"] || ErrorType.ActionNotAllowed : ErrorType.ActionNotAllowed;
        }
        case ErrorType.InvalidDate: {
            return strings ? strings["enum"]["ErrorType"]["InvalidDate"] || ErrorType.InvalidDate : ErrorType.InvalidDate;
        }
        case ErrorType.FailedUpload: {
            return strings ? strings["enum"]["ErrorType"]["FailedUpload"] || ErrorType.FailedUpload : ErrorType.FailedUpload;
        }
        case ErrorType.MissingEnvVariable: {
            return strings ? strings["enum"]["ErrorType"]["MissingEnvVariable"] || ErrorType.MissingEnvVariable : ErrorType.MissingEnvVariable;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.FieldAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["FieldAlreadyInUse"] || ErrorType.FieldAlreadyInUse : ErrorType.FieldAlreadyInUse;
        }
        case ErrorType.WrongPassword: {
            return strings ? strings["enum"]["ErrorType"]["WrongPassword"] || ErrorType.WrongPassword : ErrorType.WrongPassword;
        }
        case ErrorType.EmptyArray: {
            return strings ? strings["enum"]["ErrorType"]["EmptyArray"] || ErrorType.EmptyArray : ErrorType.EmptyArray;
        }
        case ErrorType.NotApproved: {
            return strings ? strings["enum"]["ErrorType"]["NotApproved"] || ErrorType.NotApproved : ErrorType.NotApproved;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.UserBlocked: {
            return strings ? strings["enum"]["ErrorType"]["UserBlocked"] || ErrorType.UserBlocked : ErrorType.UserBlocked;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.EmailAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyInUse"] || ErrorType.EmailAlreadyInUse : ErrorType.EmailAlreadyInUse;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.ExpiredResetPasswordToken: {
            return strings ? strings["enum"]["ErrorType"]["ExpiredResetPasswordToken"] || ErrorType.ExpiredResetPasswordToken : ErrorType.ExpiredResetPasswordToken;
        }
        case ErrorType.LoginError: {
            return strings ? strings["enum"]["ErrorType"]["LoginError"] || ErrorType.LoginError : ErrorType.LoginError;
        }
        case ErrorType.UserDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["UserDoesntExist"] || ErrorType.UserDoesntExist : ErrorType.UserDoesntExist;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.CpfAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["CpfAlreadyInUse"] || ErrorType.CpfAlreadyInUse : ErrorType.CpfAlreadyInUse;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.BadFormattedResponse,
        ErrorType.ActionNotAllowed,
        ErrorType.InvalidDate,
        ErrorType.FailedUpload,
        ErrorType.MissingEnvVariable,
        ErrorType.InternalError,
        ErrorType.FieldAlreadyInUse,
        ErrorType.WrongPassword,
        ErrorType.EmptyArray,
        ErrorType.NotApproved,
        ErrorType.NotLoggedIn,
        ErrorType.UserBlocked,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.EmailAlreadyInUse,
        ErrorType.InvalidEmail,
        ErrorType.ExpiredResetPasswordToken,
        ErrorType.LoginError,
        ErrorType.UserDoesntExist,
        ErrorType.AlreadyRegistered,
        ErrorType.CpfAlreadyInUse,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.ActionNotAllowed),
        translateErrorType(ErrorType.InvalidDate),
        translateErrorType(ErrorType.FailedUpload),
        translateErrorType(ErrorType.MissingEnvVariable),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.FieldAlreadyInUse),
        translateErrorType(ErrorType.WrongPassword),
        translateErrorType(ErrorType.EmptyArray),
        translateErrorType(ErrorType.NotApproved),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.UserBlocked),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.EmailAlreadyInUse),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.ExpiredResetPasswordToken),
        translateErrorType(ErrorType.LoginError),
        translateErrorType(ErrorType.UserDoesntExist),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.CpfAlreadyInUse),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function cropImage(src: string, imageCrop: ImageCrop, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        src: src,
        imageCrop: {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "cropImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadFile(file: UploadFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            bytes: file.bytes.toString("base64"),
            name: file.name,
        },
    };
    const ret = await makeRequest({name: "uploadFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function getCurrentAdminUser(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminUser", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
    }));
}

export async function getAdminActivityById(id: string, progress?: (progress: number) => void): Promise<AdminActivity> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminActivityById", args, progress});
    return {
        id: ret.id,
        adminId: ret.adminId,
        activityDescription: ret.activityDescription,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function getAllAdminActivities(progress?: (progress: number) => void): Promise<AdminActivity[]> {
    const ret = await makeRequest({name: "getAllAdminActivities", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        adminId: e.adminId,
        activityDescription: e.activityDescription,
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function getAdminActivitiesPaginated(page: number, progress?: (progress: number) => void): Promise<AdminActivity[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAdminActivitiesPaginated", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        adminId: e.adminId,
        activityDescription: e.activityDescription,
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function editAdminUser(adminUserId: string, editedAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
        editedAdminUser: {
            name: editedAdminUser.name,
            email: editedAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function deleteAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        adminUserId: adminUserId,
    };
    await makeRequest({name: "deleteAdminUser", args, progress});
    return undefined;
}

export async function login(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "login", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function logout(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logout", args: {}, progress});
    return undefined;
}

export async function sendResetPasswordEmailAdminUser(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendResetPasswordEmailAdminUser", args, progress});
    return undefined;
}

export async function resetPasswordEmailAdminUser(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPasswordEmailAdminUser", args, progress});
    return undefined;
}

export async function createQuiz(quizData: NewQuiz, progress?: (progress: number) => void): Promise<Quiz> {
    const args = {
        quizData: {
            name: quizData.name,
            questions: quizData.questions.map(e => ({
                description: e.description,
                options: e.options.map(e => ({
                    description: e.description,
                    value: e.value || 0,
                })),
            })),
            imcValues: quizData.imcValues === null || quizData.imcValues === undefined ? null : quizData.imcValues.map(e => ({
                imc: e.imc,
                value: e.value || 0,
            })),
            results: quizData.results.map(e => ({
                title: e.title,
                description: e.description,
                minimumValue: e.minimumValue || 0,
            })),
        },
    };
    const ret = await makeRequest({name: "createQuiz", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        questions: ret.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: ret.imcValues === null || ret.imcValues === undefined ? null : ret.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: ret.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!ret.active,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function editQuiz(quizId: string, quizData: NewQuiz, progress?: (progress: number) => void): Promise<Quiz> {
    const args = {
        quizId: quizId,
        quizData: {
            name: quizData.name,
            questions: quizData.questions.map(e => ({
                description: e.description,
                options: e.options.map(e => ({
                    description: e.description,
                    value: e.value || 0,
                })),
            })),
            imcValues: quizData.imcValues === null || quizData.imcValues === undefined ? null : quizData.imcValues.map(e => ({
                imc: e.imc,
                value: e.value || 0,
            })),
            results: quizData.results.map(e => ({
                title: e.title,
                description: e.description,
                minimumValue: e.minimumValue || 0,
            })),
        },
    };
    const ret = await makeRequest({name: "editQuiz", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        questions: ret.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: ret.imcValues === null || ret.imcValues === undefined ? null : ret.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: ret.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!ret.active,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function deleteQuiz(quizId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        quizId: quizId,
    };
    await makeRequest({name: "deleteQuiz", args, progress});
    return undefined;
}

export async function findQuizById(quizId: string, progress?: (progress: number) => void): Promise<Quiz> {
    const args = {
        quizId: quizId,
    };
    const ret = await makeRequest({name: "findQuizById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        questions: ret.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: ret.imcValues === null || ret.imcValues === undefined ? null : ret.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: ret.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!ret.active,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function findAllQuizzes(progress?: (progress: number) => void): Promise<Quiz[]> {
    const ret = await makeRequest({name: "findAllQuizzes", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        questions: e.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: e.imcValues === null || e.imcValues === undefined ? null : e.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: e.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!e.active,
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function findAllActiveQuizzes(progress?: (progress: number) => void): Promise<Quiz[]> {
    const ret = await makeRequest({name: "findAllActiveQuizzes", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        questions: e.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: e.imcValues === null || e.imcValues === undefined ? null : e.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: e.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!e.active,
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function findAllQuizzesPaginated(page: number, progress?: (progress: number) => void): Promise<Quiz[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "findAllQuizzesPaginated", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        questions: e.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: e.imcValues === null || e.imcValues === undefined ? null : e.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: e.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!e.active,
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function findAllActiveQuizzesPaginated(page: number, progress?: (progress: number) => void): Promise<Quiz[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "findAllActiveQuizzesPaginated", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        questions: e.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: e.imcValues === null || e.imcValues === undefined ? null : e.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: e.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!e.active,
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function hideQuiz(quizId: string, progress?: (progress: number) => void): Promise<Quiz> {
    const args = {
        quizId: quizId,
    };
    const ret = await makeRequest({name: "hideQuiz", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        questions: ret.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: ret.imcValues === null || ret.imcValues === undefined ? null : ret.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: ret.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!ret.active,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function unhideQuiz(quizId: string, progress?: (progress: number) => void): Promise<Quiz> {
    const args = {
        quizId: quizId,
    };
    const ret = await makeRequest({name: "unhideQuiz", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        questions: ret.questions.map((e: any) => ({
            id: e.id,
            description: e.description,
            options: e.options.map((e: any) => ({
                id: e.id,
                description: e.description,
                value: e.value || 0,
            })),
        })),
        imcValues: ret.imcValues === null || ret.imcValues === undefined ? null : ret.imcValues.map((e: any) => ({
            id: e.id,
            imc: e.imc,
            value: e.value || 0,
        })),
        results: ret.results.map((e: any) => ({
            id: e.id,
            title: e.title,
            description: e.description,
            minimumValue: e.minimumValue || 0,
        })),
        active: !!ret.active,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function getResultById(resultId: string, progress?: (progress: number) => void): Promise<Result> {
    const args = {
        resultId: resultId,
    };
    const ret = await makeRequest({name: "getResultById", args, progress});
    return {
        id: ret.id,
        quizId: ret.quizId,
        quizName: ret.quizName,
        user: {
            id: ret.user.id,
            createdAt: new Date(ret.user.createdAt + "Z"),
            name: ret.user.name,
            birthdate: ret.user.birthdate === null || ret.user.birthdate === undefined ? null : new Date(ret.user.birthdate + "Z"),
            weight: ret.user.weight === null || ret.user.weight === undefined ? null : ret.user.weight,
            height: ret.user.height === null || ret.user.height === undefined ? null : ret.user.height,
            imc: ret.user.imc === null || ret.user.imc === undefined ? null : ret.user.imc,
            sex: ret.user.sex === null || ret.user.sex === undefined ? null : ret.user.sex,
            email: ret.user.email === null || ret.user.email === undefined ? null : ret.user.email,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
        },
        imcPoints: ret.imcPoints || 0,
        questionsPoints: ret.questionsPoints || 0,
        totalPoints: ret.totalPoints || 0,
        quizResult: {
            id: ret.quizResult.id,
            title: ret.quizResult.title,
            description: ret.quizResult.description,
            minimumValue: ret.quizResult.minimumValue || 0,
        },
        answers: ret.answers.map((e: any) => ({
            questionDescription: e.questionDescription,
            selectedOption: {
                id: e.selectedOption.id,
                description: e.selectedOption.description,
                value: e.selectedOption.value || 0,
            },
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
    };
}

export async function findAllResults(progress?: (progress: number) => void): Promise<Result[]> {
    const ret = await makeRequest({name: "findAllResults", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        quizId: e.quizId,
        quizName: e.quizName,
        user: {
            id: e.user.id,
            createdAt: new Date(e.user.createdAt + "Z"),
            name: e.user.name,
            birthdate: e.user.birthdate === null || e.user.birthdate === undefined ? null : new Date(e.user.birthdate + "Z"),
            weight: e.user.weight === null || e.user.weight === undefined ? null : e.user.weight,
            height: e.user.height === null || e.user.height === undefined ? null : e.user.height,
            imc: e.user.imc === null || e.user.imc === undefined ? null : e.user.imc,
            sex: e.user.sex === null || e.user.sex === undefined ? null : e.user.sex,
            email: e.user.email === null || e.user.email === undefined ? null : e.user.email,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
        },
        imcPoints: e.imcPoints || 0,
        questionsPoints: e.questionsPoints || 0,
        totalPoints: e.totalPoints || 0,
        quizResult: {
            id: e.quizResult.id,
            title: e.quizResult.title,
            description: e.quizResult.description,
            minimumValue: e.quizResult.minimumValue || 0,
        },
        answers: e.answers.map((e: any) => ({
            questionDescription: e.questionDescription,
            selectedOption: {
                id: e.selectedOption.id,
                description: e.selectedOption.description,
                value: e.selectedOption.value || 0,
            },
        })),
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function findAllResultsPaginated(page: number, progress?: (progress: number) => void): Promise<Result[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "findAllResultsPaginated", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        quizId: e.quizId,
        quizName: e.quizName,
        user: {
            id: e.user.id,
            createdAt: new Date(e.user.createdAt + "Z"),
            name: e.user.name,
            birthdate: e.user.birthdate === null || e.user.birthdate === undefined ? null : new Date(e.user.birthdate + "Z"),
            weight: e.user.weight === null || e.user.weight === undefined ? null : e.user.weight,
            height: e.user.height === null || e.user.height === undefined ? null : e.user.height,
            imc: e.user.imc === null || e.user.imc === undefined ? null : e.user.imc,
            sex: e.user.sex === null || e.user.sex === undefined ? null : e.user.sex,
            email: e.user.email === null || e.user.email === undefined ? null : e.user.email,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
        },
        imcPoints: e.imcPoints || 0,
        questionsPoints: e.questionsPoints || 0,
        totalPoints: e.totalPoints || 0,
        quizResult: {
            id: e.quizResult.id,
            title: e.quizResult.title,
            description: e.quizResult.description,
            minimumValue: e.quizResult.minimumValue || 0,
        },
        answers: e.answers.map((e: any) => ({
            questionDescription: e.questionDescription,
            selectedOption: {
                id: e.selectedOption.id,
                description: e.selectedOption.description,
                value: e.selectedOption.value || 0,
            },
        })),
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
    }));
}

export async function createUser(newUser: UserDetails, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            name: newUser.name,
            birthdate: newUser.birthdate === null || newUser.birthdate === undefined ? null : (typeof newUser.birthdate === "string" && (newUser.birthdate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newUser.birthdate as any).replace("Z", "") : newUser.birthdate.toISOString().replace("Z", "")),
            weight: newUser.weight === null || newUser.weight === undefined ? null : newUser.weight,
            height: newUser.height === null || newUser.height === undefined ? null : newUser.height,
            imc: newUser.imc === null || newUser.imc === undefined ? null : newUser.imc,
            sex: newUser.sex === null || newUser.sex === undefined ? null : newUser.sex,
            email: newUser.email === null || newUser.email === undefined ? null : newUser.email,
            phone: newUser.phone === null || newUser.phone === undefined ? null : newUser.phone,
            image: newUser.image === null || newUser.image === undefined ? null : {
                thumb: {
                    width: newUser.image.thumb.width || 0,
                    height: newUser.image.thumb.height || 0,
                    url: newUser.image.thumb.url,
                },
                width: newUser.image.width || 0,
                height: newUser.image.height || 0,
                url: newUser.image.url,
            },
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(ret.birthdate + "Z"),
        weight: ret.weight === null || ret.weight === undefined ? null : ret.weight,
        height: ret.height === null || ret.height === undefined ? null : ret.height,
        imc: ret.imc === null || ret.imc === undefined ? null : ret.imc,
        sex: ret.sex === null || ret.sex === undefined ? null : ret.sex,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function editUser(userId: string, editedUser: UserDetails, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        editedUser: {
            name: editedUser.name,
            birthdate: editedUser.birthdate === null || editedUser.birthdate === undefined ? null : (typeof editedUser.birthdate === "string" && (editedUser.birthdate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editedUser.birthdate as any).replace("Z", "") : editedUser.birthdate.toISOString().replace("Z", "")),
            weight: editedUser.weight === null || editedUser.weight === undefined ? null : editedUser.weight,
            height: editedUser.height === null || editedUser.height === undefined ? null : editedUser.height,
            imc: editedUser.imc === null || editedUser.imc === undefined ? null : editedUser.imc,
            sex: editedUser.sex === null || editedUser.sex === undefined ? null : editedUser.sex,
            email: editedUser.email === null || editedUser.email === undefined ? null : editedUser.email,
            phone: editedUser.phone === null || editedUser.phone === undefined ? null : editedUser.phone,
            image: editedUser.image === null || editedUser.image === undefined ? null : {
                thumb: {
                    width: editedUser.image.thumb.width || 0,
                    height: editedUser.image.thumb.height || 0,
                    url: editedUser.image.thumb.url,
                },
                width: editedUser.image.width || 0,
                height: editedUser.image.height || 0,
                url: editedUser.image.url,
            },
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(ret.birthdate + "Z"),
        weight: ret.weight === null || ret.weight === undefined ? null : ret.weight,
        height: ret.height === null || ret.height === undefined ? null : ret.height,
        imc: ret.imc === null || ret.imc === undefined ? null : ret.imc,
        sex: ret.sex === null || ret.sex === undefined ? null : ret.sex,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function deleteUser(userId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userId: userId,
    };
    await makeRequest({name: "deleteUser", args, progress});
    return undefined;
}

export async function blockUser(userId: string, isBlocked: boolean, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        isBlocked: !!isBlocked,
    };
    const ret = await makeRequest({name: "blockUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(ret.birthdate + "Z"),
        weight: ret.weight === null || ret.weight === undefined ? null : ret.weight,
        height: ret.height === null || ret.height === undefined ? null : ret.height,
        imc: ret.imc === null || ret.imc === undefined ? null : ret.imc,
        sex: ret.sex === null || ret.sex === undefined ? null : ret.sex,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function getUser(userId: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(ret.birthdate + "Z"),
        weight: ret.weight === null || ret.weight === undefined ? null : ret.weight,
        height: ret.height === null || ret.height === undefined ? null : ret.height,
        imc: ret.imc === null || ret.imc === undefined ? null : ret.imc,
        sex: ret.sex === null || ret.sex === undefined ? null : ret.sex,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function getUsers(pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
        name: e.name,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(e.birthdate + "Z"),
        weight: e.weight === null || e.weight === undefined ? null : e.weight,
        height: e.height === null || e.height === undefined ? null : e.height,
        imc: e.imc === null || e.imc === undefined ? null : e.imc,
        sex: e.sex === null || e.sex === undefined ? null : e.sex,
        email: e.email === null || e.email === undefined ? null : e.email,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
    }));
}

export async function getTotalNumberUsers(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalNumberUsers", args: {}, progress});
    return ret || 0;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
