import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	IconButton,
	TableCell,
	TableRow,
	FormControlLabel,
	Switch,
} from "@material-ui/core";
import { Visibility, Edit, Delete, Add as AddIcon } from "@material-ui/icons";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import PageTableContainer from "../../../../components/PageTableContainer";
import TableFallback from "../../../../components/TableFallback";
import SimpleTable from "../../../../components/SimpleTable";
import { useGlobalStore } from "../../../context";
import QuizTableStore from "./store";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(
		() => new QuizTableStore(uiStore, history),
	);

	const pushToDetails = useCallback(
		(id: string) => history.push(`quizzes/details/${id}`),
		[history],
	);

	const pushToCreate = useCallback(() => history.push(`quizzes/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`quizzes/edit/${id}`),
		[history],
	);

	const handleDeleteQuiz = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: "Deseja mesmo excluir esse questionário?",
					title: Strings.dialogs.delete.title,
				},
				() => tableStore.deleteQuiz(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				fallback={tableStore._loading ? <></> : <TableFallback />}
				title={Strings.quizz.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.name,
					Strings.components.table.detailsAndEditAndDelete,
					Strings.components.table.activateAndDeactivate,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
							<IconButton
								onClick={() => handleDeleteQuiz(item.id)}
							>
								<Delete />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							<FormControlLabel
								control={
									<Switch
										checked={item.active}
										onChange={() => {
											tableStore.hideOrUnhideQuiz(
												item.id,
												item.active ? "hide" : "unhide",
											);
										}}
									/>
								}
								label={
									item.active
										? Strings.quizz.table.active
										: Strings.quizz.table.inactive
								}
							/>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<Button startIcon={<AddIcon />} onClick={pushToCreate}>
						{Strings.actions.create}
					</Button>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
