import LocalizedStrings from "localized-strings";

// MARK: Implementation
const Strings = new LocalizedStrings({
	ptBR: {
		enum: {
			LessonType: {
				preparatory: "Preparatório",
				compensatory: "Compensatório",
				relaxation: "Relaxamento",
			},
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
		},
		fields: {
			id: "id",
			adminId: "id do administrador",
			archive: "Arquivar",
			document: "Documentação",
			type: "Tipo",
			slug: "slug",
			image: "Imagem",
			url: "Url",
			date: "Data",
			minAmount: "Quantidade miníma",
			diameter: "Diâmetro",
			images: "Imagens",
			phone: "Telefone",
			price: "Preço",
			productionTime: "Tempo de produção",
			whatsapp: "Whatsapp",
			category: "Categoria",
			name: "Nome",
			status: "Status",
			nick: "Apelido",
			birthdate: "Data de Nascimento",
			cpf: "CPF",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			save: "Salvar",
			email: "Email",
			account: "Conta",
			text: "Texto",
			message: "Mensagem",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			recovery: "Recuperar Senha",
			title: "Título",
			description: "Descrição",
			createdAt: "Criado em",
			block: "Data de bloqueio",
			user: "Usuário",
		},
		components: {
			table: {
				noMoreResults: "Sem mais resultados",
				create: "Criar",
				delete: "Deletar",
				details: "Detalhes",
				edit: "Editar",
				export: "Exportar",
				accept: "Aceitar",
				reject: "Reject",
				activateAndDeactivate: "Ativar / Desativar",
				fallbackData: "Não há dados correspondentes",
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				blockAndEditAndDelete: "Editar / Bloquear / Deletar",
				detailsAndblockAndEditAndDelete:
					"Vizualizar / Editar / Bloquear / Deletar",
				detailsAndEditAndDelete: "Editar / Detalhes / Deletar",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
		},
		notInformed: "Não informado",
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},

		dialogs: {
			deleteContact: (name: string) => ({
				title: "Apagar Contato",
				message:
					"Tem certeza que deseja apagar o contato " +
					name +
					" ?, essa operação não pode ser revertida.",
			}),
			delete: {
				title: "Confirmação de exclusão",
				message: "Deseja mesmo excluir este usuário?",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},

		loading: "Carregando",
		hiAdmin: "Olá, Administrador(a)",
		error: {
			needLongerText: (numOfChars: number) =>
				"Precisa ser maior que " + numOfChars + " caracteres.",
			invalidEmail: "Email inválido",
			invalidPhone: "Telefone inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			nameIsEmpty: "O nome está vazio",
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
			mustWaitWhileStillLoading:
				"Ainda em carregamento, aguarde um momento.",
			missingImage: "É necessario anexar a imagem",
			missingClient: "É Necessário Selecionar Um Cliente!",
			missingPhase: "É Necessário Selecionar A Fase Do Projeto",
			aleredySelected: "Já Selecionado!",
			clientNotFound: (id?: string) =>
				`Cliente ${id && "[" + id + "] "}não encontrado.`,
		},

		nav: {
			title: "Dr. Diabetes",
			home: "Inicio",
			lessons: "Aulas",
			users: "Usuários",
			adminUsers: "Usuários Admin",
			adminActivities: "Atividades dos administradores",
			quizzes: "Questionários",
			results: "Resultados",
			logout: "Sair",
			configure: "Configurações",
			about: "Sobre",
			disconected: "Você está descontectado da internet",
		},

		pageLogin: {
			pagetitle: "Dr. Diabetes",
			title: "Acesso",
			buttonLogin: "Entrar",
			noAccess: "Ainda não possui acesso?",
			requestAccess: "Solicite Aqui",
			recoveryPassword: "Esqueceu a senha?",
			messageFooter: "Desenvolvido de ponta a ponta pela Startapp",
			needHelp: "Precisa de ajuda?",
		},
		home: {
			welcome: "Bem vindo ao dashboard do Dr. Diabetes",
		},
		admin: {
			table: {
				title: "Tabela de administradores",
			},
			details: {
				title: "Detalhes do administrador",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar administrador",
				createButton: "Criar",
			},
			edit: {
				title: "editar administrador",
			},
		},
		adminActivities: {
			table: {
				title: "Histórico de atividades dos administradores",
			},
			details: {
				title: "Detalhes da atividade do administrador",
				header: { name: "Nome", email: "Email" },
			},
		},
		adminUsers: {
			title: "Administradores",
			editor: {
				getTitle: (onEditMode = true) =>
					onEditMode
						? "Editar Administrador"
						: "Cadastrar Administrador",
			},
			edit: {
				title: (adminUserName?: string) =>
					"Edição do Administrador " + adminUserName,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (adminUserName: string) =>
					`${adminUserName} modificado com sucesso!`,
			},
			table: {
				header: {
					name: "Nome",
					phone: "Telefone",
					email: "Email",
					editDelete: "Editar / Deletar",
				},
				tableIsEmpty: "Não foi possivel encontrar mais administradores",
			},
			deleteDialogAdmin: {
				title: "Deletar Administrador",
				message: "Tem certeza que deseja deletar este administrador?",
			},
			csvHeaders: [
				{ label: "Id", key: "id" },
				{ label: "Nome", key: "name" },
				{ label: "E-mail", key: "email" },
			],
		},
		quizz: {
			create: {
				title: "Criar questionário",
				createButton: "Criar",
				success: "Questionário criado com sucesso!",
			},
			table: {
				title: "Tabela de Questionários",
				active: "Ativo",
				inactive: "Inativo",
			},
			details: {
				title: "Detalhes do questionário",
			},
			delete: {
				title: "Deletar questionário",
				message: "Tem certeza que deseja deletar este questionário?",
			},
			edit: {
				title: "Editar questionário",
				editButton: "Editar",
				success: "Questionário editado com sucesso!",
			},
		},

		users: {
			table: {
				title: "Tabela de usuários",
				userActivate: "Usuário ativo",
				TypeUser: {
					title: "Tipo de Usuário",
					seller: "Vendedor",
					customer: "Cliente",
				},
			},
			details: {
				title: "Detalhes do usuário",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar usuário",
				createButton: "Criar",
			},
			edit: {
				title: (userName?: string) => "Edição do Usuário " + userName,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (userName: string) =>
					`${userName} modificado com sucesso!`,
			},
			blockDialogUser: {
				title: (blockUser: boolean) =>
					`${blockUser ? "Bloquear" : "Desbloquear"} usuário`,
				message: (blockUser: boolean) =>
					`Tem certeza que deseja ${
						blockUser ? "bloquear" : "desbloquear"
					} este usuário?`,
			},
		},

		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		register: {
			success: "Administrador criado com sucesso",
			successUser: "Usúario criado com sucesso",
		},
	},
});

export default Strings;
