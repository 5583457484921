import { runInAction, makeObservable, observable } from "mobx";
import { Errors } from "../modules/Errors";
import UIStore from "../stores/UIStore";

export default abstract class ModelStore<ModelType, IdType = string> {
	public model: ModelType;
	public loading: boolean = false;
	public error: string | null = null;
	protected id: IdType;

	protected uiStore: UIStore;

	constructor(id: IdType, uiStore: UIStore) {
		this.fetchModel(id);
		this.uiStore = uiStore;
		this.id = id;
		makeObservable(this, {
			loading: observable,
			error: observable,
		});
	}

	protected abstract getModel(id: IdType): Promise<ModelType>;

	private fetchModel = async (id: IdType) => {
		try {
			runInAction(() => (this.loading = true));
			const resultModel = await this.getModel(id);
			runInAction(() => (this.model = resultModel));
			this.afterModelFetch(this.model);
		} catch (e) {
			runInAction(() => (this.error = Errors.handleError(e)));
			this.afterModelFetchError();
		} finally {
			runInAction(() => (this.loading = false));
			this.afterAll();
		}
	};

	protected afterModelFetch(model: ModelType) {}

	protected afterModelFetchError() {}

	protected afterAll() {}
}
