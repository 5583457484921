import * as Yup from "yup";

export const questionValidationSchema = Yup.object().shape({
	description: Yup.string().required("As perguntas devem ter um enunciado."),
	options: Yup.array().of(
		Yup.object().shape({
			description: Yup.string().required(
				"As respostas devem ter uma descrição.",
			),
			value: Yup.number()
				.typeError("As respostas devem ter um valor numérico.")
				.required("As respostas devem ter um valor.")
				.min(0, "O valor das respostas deve ser positivo."),
		}),
	),
});
