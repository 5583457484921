import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import UIStore from "../../../../stores/UIStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class AdminTableStore extends PaginatedListStore<api.AdminUser> {
	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
		this.uiStore = uiStore;
	}

	protected getDataItemsPerPage(page: number): Promise<api.AdminUser[]> {
		return api.getAdminUsers(page);
	}

	public deleteAdminUser = async (id: string) => {
		try {
			await api.deleteAdminUser(id);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};
}
