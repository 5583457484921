import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		divider: {
			marginTop: 40,
			marginBottom: 40,
		},

		nameField: {
			minWidth: 400,
		},
	}),
);

export default useStyles;
