import { makeAutoObservable } from "mobx";
import UIStore from "../../../../../stores/UIStore";
import API from "../../../../../modules/API";

export default class Store {
	private uiStore: UIStore;

	public answers: API.NewQuestionOption[] = [{ description: "", value: 0 }];

	public addBlankQuestion = () => {
		this.answers.push({ description: "", value: 0 });
	};

	public removeAnswer = (answerIndex: number) => {
		if (this.answers.length > 1) {
			this.answers = this.answers.filter(
				(_, index) => index !== answerIndex,
			);
		} else {
			this.uiStore.showSnackbar(
				"Perguntas devem ter pelo menos uma opção de resposta",
			);
		}
	};

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		makeAutoObservable(this);
	}
}
