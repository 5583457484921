import { runInAction, makeObservable, observable } from "mobx";
import API from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import ModelStore from "../../../../stores/ModelStore";

export default class Store extends ModelStore<API.Quiz> {
	public quiz: API.Quiz | null = null;

	constructor(id: string, uiStore: UIStore) {
		super(id, uiStore);
		this.getAdminUser(id);
		makeObservable(this, {
			quiz: observable,
		});
	}

	protected async getModel(id: string) {
		return await API.findQuizById(id);
	}

	public getAdminUser = async (id: string) => {
		const resultQuiz = await API.findQuizById(id);
		runInAction(() => (this.quiz = resultQuiz));
	};
}
