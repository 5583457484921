import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boldText: {
			fontWeight: 700,
		},
	}),
);

export default useStyles;
