import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	TextField,
	Typography,
	Box,
	Card,
	CardHeader,
	CardContent,
} from "@material-ui/core";
import moment from "moment";
import { useGlobalStore } from "../../../context";
import Strings from "../../../../modules/Strings";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import UserDataCard from "./UserDataCard";
import QuizResultCard from "./QuizResultCard";
import Store from "./store";
import AnswerCard from "./AnswerCard";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(id, uiStore));

	const roundOneDecimal = (
		value: number | null | undefined,
	): number | null => {
		if (!value) {
			return null;
		}

		return Math.round(value * 10) / 10;
	};

	return (
		<CardForm title="Detalhes do resultado">
			{!store.result ? (
				<LinearProgress />
			) : (
				<>
					<Card className={classes.card}>
						<CardHeader title="Questionário" />
						<CardContent>
							<Box className={classes.inputsContainer}>
								<TextField
									label="Nome do questionário"
									value={store.result.quizName}
									disabled={true}
									className={classes.input}
									InputProps={{
										classes: {
											root: classes.inputRoot,
											disabled: classes.disabled,
										},
									}}
								/>
								<TextField
									label="Data de realização"
									value={moment(
										store.result?.editedAt,
									).format(Strings.moment.date)}
									disabled={true}
									className={classes.input}
									InputProps={{
										classes: {
											root: classes.inputRoot,
											disabled: classes.disabled,
										},
									}}
								/>
							</Box>
						</CardContent>
					</Card>

					<UserDataCard store={store} />

					<QuizResultCard store={store} />

					<Typography variant="h5" className={classes.title}>
						Respostas
					</Typography>

					{store.result.answers.map((answer, index) => (
						<AnswerCard key={index} answer={answer} />
					))}

					<Card className={classes.card}>
						<CardContent>
							<TextField
								label="Pontuação referente ao IMC"
								value={
									roundOneDecimal(store.result.imcPoints) || 0
								}
								disabled={true}
								className={classes.input}
								InputProps={{
									classes: {
										root: classes.inputRoot,
										disabled: classes.disabled,
									},
								}}
							/>
						</CardContent>
					</Card>
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
