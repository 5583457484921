import React from "react";
import useStyles from "./styles";
import { observer } from "mobx-react-lite";
import { TextField, TableRow, TableCell, Box } from "@material-ui/core";
import SimpleTable from "../../../../../components/SimpleTable";
import Store from "../store";

interface IProps {
	store: Store;
}

const ResultsFormTable: React.FC<IProps> = (props) => {
	const classes = useStyles();
	return !props.store.quiz ? (
		<></>
	) : (
		<SimpleTable
			title="Opções de resultado"
			rows={props.store.quiz.results}
			headers={["Dados do resultado"]}
			isSmallContainer={true}
			renderItem={(item, index) => (
				<TableRow hover={true} key={index}>
					<TableCell align="center">
						<Box className={classes.inputRowBoxWithMargin}>
							<TextField
								label="Título do resultado"
								className={classes.titleField}
								disabled={true}
								value={item.title}
							/>

							<TextField
								label="Valor mínimo"
								disabled={true}
								value={item.minimumValue}
							/>
						</Box>
						<Box className={classes.inputRowBox}>
							<TextField
								label="Descrição e recomendações"
								className={classes.descriptionField}
								multiline={true}
								disabled={true}
								value={item.description}
							/>
						</Box>
					</TableCell>
				</TableRow>
			)}
		/>
	);
};

export default observer(ResultsFormTable);
