import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			marginTop: 10,
			marginBottom: 10,
		},
		title: {
			marginTop: 10,
			marginBottom: 10,
			marginLeft: 10,
		},
		inputsContainer: {
			display: "flex",
			flex: 1,
			marginBottom: 20,
		},
		input: {
			width: "100%",
			marginRight: 10,
			marginLeft: 10,
		},
		inputRoot: {
			"&$disabled": {
				color: "#1d1d1d",
			},
		},
		disabled: {},
	}),
);

export default useStyles;
