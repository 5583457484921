import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import SimpleTable from "../../../../../components/SimpleTable";
import Store from "../store";
import { observer } from "mobx-react-lite";

interface IProps {
	store: Store;
	setSelectedQuestionIndex: React.Dispatch<
		React.SetStateAction<number | undefined>
	>;
}

const QuestionsTable: React.FC<IProps> = (props) => {
	return !props.store.quiz ? (
		<></>
	) : (
		<SimpleTable
			title="Perguntas"
			rows={props.store.quiz.questions}
			headers={["Pergunta", "Detalhes"]}
			isSmallContainer={true}
			renderItem={(item, index) => (
				<TableRow hover={true} key={index}>
					<TableCell align={"center"}>{item.description}</TableCell>
					<TableCell align={"center"}>
						<IconButton
							onClick={() => {
								props.setSelectedQuestionIndex(index);
							}}
						>
							<Visibility />
						</IconButton>
					</TableCell>
				</TableRow>
			)}
		/>
	);
};

export default observer(QuestionsTable);
