import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { IconButton, TableCell, TableRow, Button } from "@material-ui/core";
import { Visibility, GetApp } from "@material-ui/icons";
import Strings from "../../../../modules/Strings";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import TableFallback from "../../../../components/TableFallback";
import { useGlobalStore } from "../../../context";
import ResultsTableStore from "./store";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(
		() => new ResultsTableStore(uiStore, history),
	);

	const pushToDetails = useCallback(
		(id: string) => history.push(`results/details/${id}`),
		[history],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				fallback={tableStore._loading ? <></> : <TableFallback />}
				title="Tabela de Resultados"
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					"id do resultado",
					"Nome do questionário",
					"Data de realização",
					"Detalhes",
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.quizName}</TableCell>
						<TableCell align={"center"}>
							{moment(item.editedAt).format(Strings.moment.date)}
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<>
						<Button
							startIcon={<GetApp />}
							onClick={tableStore.downloadCurrentPageResultsCsv}
						>
							Exportar resultados da página como CSV
						</Button>
						<Button
							startIcon={<GetApp />}
							onClick={tableStore.downloadAllResultsCsv}
						>
							Exportar todos os resultados como CSV
						</Button>
					</>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
