import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useLocalStore, observer } from "mobx-react-lite";
import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import moment from "moment";
import Strings from "../../../../modules/Strings";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import TableFallback from "../../../../components/TableFallback";
import { useGlobalStore } from "../../../context";
import AdminTableStore from "./store";

const formatDate = (date: Date) => {
	return moment(date).format(Strings.moment.dateTime);
};

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(
		() => new AdminTableStore(uiStore, history),
	);

	const pushToAdminDetails = useCallback(
		(id: string) => history.push(`adminUsers/details/${id}`),
		[history],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				fallback={tableStore._loading ? <></> : <TableFallback />}
				title={Strings.adminActivities.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.adminId,
					Strings.fields.date,
					Strings.fields.description,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>
							{item.adminId}
							<IconButton
								onClick={() => pushToAdminDetails(item.adminId)}
							>
								<Person />
							</IconButton>
						</TableCell>
						<TableCell align={"center"}>
							{formatDate(item.createdAt)}
						</TableCell>
						<TableCell align={"center"}>
							<Typography variant="body1" align="left">
								{item.activityDescription}
							</Typography>
						</TableCell>
					</TableRow>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
