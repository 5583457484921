import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		fallbackText: {
			marginTop: 20,
			marginLeft: 20,
			paddingBottom: 20,
		},
	}),
);

export default useStyles;
