import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.primary.main,
	},
	alert: {
		display: "flex",
		alignItems: "center",
		height: 70,
	},
}));
