import { runInAction, makeAutoObservable } from "mobx";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import * as Yup from "yup";
import UIStore from "../../../../stores/UIStore";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";

export interface IRequest {
	name: string;
	email: string;
	password: string;
}

interface IPush {
	goBack: () => void;
}

export default class Store {
	private uiStore: UIStore;
	private history: IPush;

	public loading = false;
	public error: string | null = null;

	public avatarController: FileStore | null = null;
	public formController = new FormStore({
		email: "",
		name: "",
		password: "",
	});

	constructor(uiStore: UIStore, history: IPush) {
		this.uiStore = uiStore;
		this.history = history;
		this.avatarController = new FileStore(uiStore, "image");
		makeAutoObservable(this);
	}

	public createAdminUser = async () => {
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			runInAction(() => (this.loading = true));
			await API.createAdminUser(this.formController.getValues());
			this.history.goBack();
			this.uiStore.showSnackbar(Strings.register.success);
		} catch (error) {
			runInAction(() => (this.error = Errors.handleError(error)));
			this.uiStore.showErrorSnackbar(this.error);
		} finally {
			runInAction(() => (this.loading = false));
		}
	};

	public getValidations(): Yup.ObjectSchema<object | undefined, object> {
		return Yup.object().shape({
			name: Yup.string().required("O nome é obrigatório"),
			email: Yup.string()
				.email("Informe um email valido")
				.required("O email é obrigatório"),
			password: Yup.string().required("A senha é obrigatória"),
		});
	}
}
