import React from "react";
import Strings from "../../modules/Strings";
import { observer } from "mobx-react";
import { Box, Typography } from "@material-ui/core";

const FallbackTable: React.FC = () => {
	return (
		<Box
			marginTop="30px"
			flex="1"
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<Typography variant="h5">
				{Strings.components.table.noMoreResults}
			</Typography>
		</Box>
	);
};

export default observer(FallbackTable);
