import React from "react";
import useStyles from "../styles";
import {
	Card,
	CardHeader,
	CardContent,
	Box,
	TextField,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Strings from "../../../../../modules/Strings";
import Store from "../store";
import API from "../../../../../modules/API";

interface IProps {
	store: Store;
}

const UserDataCard: React.FC<IProps> = (props) => {
	const { store } = props;

	const roundOneDecimal = (
		value: number | null | undefined,
	): number | null => {
		if (!value) {
			return null;
		}

		return Math.round(value * 10) / 10;
	};

	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<CardHeader title="Usuário" />
			<CardContent>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Nome do usuário"
						value={store.result?.user.name || ""}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
					<TextField
						label="Data de nascimento"
						value={
							moment(store.result?.user.birthdate).format(
								Strings.moment.date,
							) || Strings.notInformed
						}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Altura (cm)"
						value={
							roundOneDecimal(store.result?.user.height) ||
							Strings.notInformed
						}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
					<TextField
						label="Peso (kg)"
						value={
							roundOneDecimal(store.result?.user.weight) ||
							Strings.notInformed
						}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
				<Box className={classes.inputsContainer}>
					<TextField
						label="IMC"
						value={
							roundOneDecimal(store.result?.user.imc) ||
							Strings.notInformed
						}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
					<TextField
						label="Sexo"
						value={
							(store.result?.user.sex === API.UserDetailsSex.male
								? "Masculino"
								: "Feminino") || Strings.notInformed
						}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Email"
						value={store.result?.user.email || Strings.notInformed}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
					<TextField
						label="Telefone"
						value={store.result?.user.phone || Strings.notInformed}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
			</CardContent>
		</Card>
	);
};

export default observer(UserDataCard);
