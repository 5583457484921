import {
	Home as HomeIcon,
	SupervisorAccount as AdminIcon,
	History as AdminActivitiesIcon,
	QuestionAnswer as QuizzesIcon,
	CheckCircle as ResultsIcon,
} from "@material-ui/icons";
import Strings from "../modules/Strings";
export function useMainRoutes() {
	return [
		{
			Icon: HomeIcon,
			text: Strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: AdminIcon,
			text: Strings.nav.adminUsers,
			path: "/dashboard/adminUsers",
		},
		{
			Icon: AdminActivitiesIcon,
			text: Strings.nav.adminActivities,
			path: "/dashboard/adminActivities",
		},
		{
			Icon: QuizzesIcon,
			text: Strings.nav.quizzes,
			path: "/dashboard/quizzes",
		},
		{
			Icon: ResultsIcon,
			text: Strings.nav.results,
			path: "/dashboard/results",
		},
	];
}
