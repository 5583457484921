import React from "react";
import useStyles from "./styles";
import {
	Box,
	Button,
	TextField,
	TableRow,
	TableCell,
	IconButton,
	FormControlLabel,
	Switch,
	Typography,
} from "@material-ui/core";
import { Delete, Add as AddIcon, Help } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import {
	Controller,
	Control,
	FormState,
	UseFormUnregister,
	UseFormSetValue,
	UseFormGetValues,
} from "react-hook-form";
import API from "../../../../../modules/API";
import SimpleTable from "../../../../../components/SimpleTable";
import Store from "../store";

type ImcValueField = "imc" | "value";

const imcValuesError = (
	formState: FormState<API.NewQuiz>,
	field: ImcValueField,
	index: number,
) => {
	if (formState.errors.imcValues) {
		const imcValuesErrors = formState.errors.imcValues;
		return {
			hasError: !!imcValuesErrors[index]?.[field],
			errorMessage: imcValuesErrors[index]?.[field]?.message,
		};
	}

	return undefined;
};

interface IProps {
	store: Store;
	control: Control<API.NewQuiz>;
	formState: FormState<API.NewQuiz>;
	setValue: UseFormSetValue<API.NewQuiz>;
	getValues: UseFormGetValues<API.NewQuiz>;
	unregister: UseFormUnregister<API.NewQuiz>;
	setIsHelpVisibe: React.Dispatch<React.SetStateAction<boolean>>;
}

// tslint:disable-next-line:max-func-body-length
const ImcValuesTable: React.FC<IProps> = (props) => {
	const classes = useStyles();

	return (
		<>
			<Typography variant="h6">
				Habilitar/Desabilitar impacto do IMC no resultado
			</Typography>
			<FormControlLabel
				control={
					<Switch
						checked={props.store.hasImcValues}
						onChange={() => {
							props.store.onChangeHasImcValues();
							if (!props.store.hasImcValues) {
								props.unregister("imcValues");
							}
						}}
					/>
				}
				label={"Impacto do IMC no resultado"}
			/>
			{props.store.imcValues !== null && (
				<SimpleTable
					rows={props.store.imcValues}
					headers={[
						"IMC mínimo para somar o valor ao resultado",
						"Deletar",
					]}
					isSmallContainer={true}
					renderItem={(item, index) => (
						<TableRow hover={true} key={index}>
							<TableCell align="center">
								<Box className={classes.inputRowBoxWithMargin}>
									<Controller
										name={
											`imcValues[${index}].imc` as `imcValues.${number}.imc`
										}
										control={props.control}
										defaultValue={
											props.store.imcValues
												? props.store.imcValues[index]
														.imc
												: ""
										}
										render={({ field }) => (
											<TextField
												label="IMC mínimo"
												type="number"
												className={classes.imcField}
												{...field}
												error={
													!!imcValuesError(
														props.formState,
														"imc",
														index,
													)?.hasError
												}
												helperText={
													imcValuesError(
														props.formState,
														"imc",
														index,
													)?.errorMessage
												}
											/>
										)}
									/>

									<Controller
										name={
											`imcValues[${index}].value` as `imcValues.${number}.value`
										}
										control={props.control}
										defaultValue={
											props.store.imcValues
												? props.store.imcValues[index]
														.value
												: ""
										}
										render={({ field }) => (
											<TextField
												label="Valor (pontos)"
												className={classes.valueField}
												type="number"
												{...field}
												error={
													!!imcValuesError(
														props.formState,
														"value",
														index,
													)?.hasError
												}
												helperText={
													imcValuesError(
														props.formState,
														"value",
														index,
													)?.errorMessage
												}
											/>
										)}
									/>
								</Box>
							</TableCell>
							<TableCell align={"center"}>
								<IconButton
									onClick={() => {
										props.store.removeImcValue(index);
										props.unregister([
											`imcValues[${index}].imc` as `imcValues.${number}.imc`,
											`imcValues[${index}].value` as `imcValues.${number}.value`,
										]);
									}}
								>
									<Delete />
								</IconButton>
							</TableCell>
						</TableRow>
					)}
					bottomToolbar={
						<>
							<Button
								startIcon={<AddIcon />}
								onClick={props.store.addBlankImcValue}
							>
								Adicionar valor de IMC
							</Button>

							<Button
								startIcon={<Help />}
								onClick={() => {
									props.setIsHelpVisibe(true);
								}}
								className={classes.helpButton}
							>
								Ajuda sobre pontuação do IMC
							</Button>
						</>
					}
				/>
			)}
		</>
	);
};

export default observer(ImcValuesTable);
