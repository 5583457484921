import React from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route } from "react-router-dom";
import TableView from "./TableView";

interface IProps {}

const AdminActivities: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route
				exact={true}
				path="/dashboard/adminActivities"
				component={TableView}
			/>
		</Switch>
	);
};

export default observer(AdminActivities);
