import React from "react";
import useStyles from "../styles";
import {
	Card,
	CardHeader,
	CardContent,
	Box,
	TextField,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import Store from "../store";

interface IProps {
	store: Store;
}

const QuizResultCard: React.FC<IProps> = (props) => {
	const { store } = props;

	const roundOneDecimal = (
		value: number | null | undefined,
	): number | null => {
		if (!value) {
			return null;
		}

		return Math.round(value * 10) / 10;
	};

	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<CardHeader title="Resultado" />
			<CardContent>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Título"
						value={store.result?.quizResult.title || ""}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Descrição e recomendações"
						multiline={true}
						value={store.result?.quizResult.description || ""}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
				<Box className={classes.inputsContainer}>
					<TextField
						label="Valor mínimo para ter o resultado"
						value={
							roundOneDecimal(
								store.result?.quizResult.minimumValue,
							) || 0
						}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
					<TextField
						label="Valor atingido pelo usuário"
						value={roundOneDecimal(store.result?.totalPoints) || 0}
						disabled={true}
						className={classes.input}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								disabled: classes.disabled,
							},
						}}
					/>
				</Box>
			</CardContent>
		</Card>
	);
};

export default observer(QuizResultCard);
