import React from "react";
import { useStyles } from "./styles";
import { observer } from "mobx-react-lite";
import { PageContainer } from "../PageContainer";
import {
	Box,
	Card,
	CardContent,
	CardActions,
	Divider,
	LinearProgress,
} from "@material-ui/core";
import { BackButtonHeader } from "../BackButtonHeader";
import { SimpleHeader } from "../SimpleHeader";

interface IProps {
	title: string;
	loading?: boolean;
	actionsFooter?: React.ReactElement | null;
	backButtonDisabled?: boolean;
}

const CardForm: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const { title, loading, actionsFooter } = props;

	return (
		<PageContainer>
			{loading ? <LinearProgress /> : null}
			<Card className={classes.root}>
				{!props.backButtonDisabled ? (
					<BackButtonHeader title={title} />
				) : (
					<SimpleHeader title={title} />
				)}
				<Box
					className={classes.box}
					display="flex"
					flexDirection="column"
				>
					<Divider />
					<CardContent className={classes.cardContent}>
						{props.children}
					</CardContent>
				</Box>
				<Divider />
				<CardActions>{actionsFooter}</CardActions>
			</Card>
		</PageContainer>
	);
};

export default observer(CardForm);
