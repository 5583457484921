import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import Home from "./Home";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import AdminUsers from "./AdminUsers";
import AdminActivities from "./AdminActivities";
import Quizzes from "./Quizzes";
import Results from "./Results";

export const Dashboard = () => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route exact={true} path="/dashboard" component={Home} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route
					path="/dashboard/adminActivities"
					component={AdminActivities}
				/>
				<Route path="/dashboard/quizzes" component={Quizzes} />
				<Route path="/dashboard/results" component={Results} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
