import React from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route } from "react-router-dom";
import TableView from "./TableView";
import DetailsView from "./DetailsView";

interface IProps {}

const Results: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route
				exact={true}
				path="/dashboard/results"
				component={TableView}
			/>
			<Route
				exact={true}
				path="/dashboard/results/details/:id"
				component={DetailsView}
			/>
		</Switch>
	);
};

export default observer(Results);
