import React from "react";
import "./style.scss";

import { Auth } from "./Auth";
import { Login } from "./Login";
import { Dashboard } from "./Dashboard";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { GlobalContextProvider } from "./context";
import { CssBaseline } from "@material-ui/core";

export function App() {
	return (
		<GlobalContextProvider>
			<CssBaseline />
			<BrowserRouter>
				<Switch>
					<Route path="/dashboard">
						<Dashboard />
					</Route>
					<Route exact={true} path="/">
						<Auth />
					</Route>
					<Route path="/login">
						<Login />
					</Route>
				</Switch>
			</BrowserRouter>
		</GlobalContextProvider>
	);
}
