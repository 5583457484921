import React from "react";
import useStyles from "./styles";
import { Box, TextField, TableRow, TableCell } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import SimpleTable from "../../../../../components/SimpleTable";
import Store from "../store";

interface IProps {
	store: Store;
}

const ImcValuesTable: React.FC<IProps> = (props) => {
	const classes = useStyles();

	return (
		<>
			{props.store.quiz && props.store.quiz.imcValues !== null && (
				<SimpleTable
					title="Peso do IMC no resultado"
					rows={props.store.quiz.imcValues}
					headers={["IMC mínimo para somar o valor ao resultado"]}
					isSmallContainer={true}
					renderItem={(item, index) => (
						<TableRow hover={true} key={index}>
							<TableCell align="center">
								<Box className={classes.inputRowBoxWithMargin}>
									<TextField
										label="IMC mínimo"
										value={item.imc}
										type="number"
										className={classes.imcField}
										disabled={true}
									/>

									<TextField
										label="Valor (pontos)"
										value={item.value}
										className={classes.valueField}
										type="number"
										disabled={true}
									/>
								</Box>
							</TableCell>
						</TableRow>
					)}
				/>
			)}
		</>
	);
};

export default observer(ImcValuesTable);
