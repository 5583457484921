// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Styles
import useStyles from "./CardUploaded/style";

// MARK: Material Components
import { Button, Typography, Box } from "@material-ui/core";

// MARK: Components
import CardUploaded from "./CardUploaded";

// MARK: Icons
import { Add } from "@material-ui/icons";

// MARK: Resources
import Strings from "../../modules/Strings";
import FileUploader from "../../stores/FileStore/FileUploader";
interface IProps {
	onAddItem: () => void;
	onRemoveItem: (fileId: string) => void;
	onReloadItem: (fileId: string) => void;
	fileUploaders: FileUploader[] | FileUploader | null;
	title?: string;
	disableActions?: boolean;
}

const FileCase: React.FC<IProps> = (props) => {
	const {
		onReloadItem,
		fileUploaders,
		onAddItem,
		onRemoveItem,
		title,
		disableActions,
	} = props;

	const classes = useStyles();

	const renderIfNoImagesYet = () => {
		if (Array.isArray(fileUploaders) && fileUploaders.length === 0) {
			return <h4>{Strings.imageCase.addFilesPlaceholder}</h4>;
		} else if (!fileUploaders) {
			return <h4>{Strings.imageCase.addFilePlaceholder}</h4>;
		}
		return;
	};

	return (
		<React.Fragment>
			<Box
				style={{
					marginTop: 18,
					marginBottom: 12,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<Typography variant="subtitle1">
					{title ? title : "Arquivos"}
				</Typography>
				<Button
					size="small"
					onClick={onAddItem}
					startIcon={<Add />}
					className={disableActions ? classes.disabledActions : "null"}
				>
					{Strings.imageCase.addImageBtn}
				</Button>
			</Box>
			{fileUploaders ? (
				Array.isArray(fileUploaders) ? (
					<Box>
						{fileUploaders.map((uploader) => (
							<CardUploaded
								key={uploader.id}
								uploader={uploader}
								name={uploader.name}
								removeItem={() => onRemoveItem(uploader.id)}
								reloadItem={() => onReloadItem(uploader.id)}
								disableActions={disableActions}
							/>
						))}
					</Box>
				) : (
					<Box>
						<CardUploaded
							key={fileUploaders.id}
							uploader={fileUploaders}
							name={fileUploaders.name}
							removeItem={() => onRemoveItem(fileUploaders.id)}
							reloadItem={() => onReloadItem(fileUploaders.id)}
							disableActions={disableActions}
						/>
					</Box>
				)
			) : null}
			{renderIfNoImagesYet()}
		</React.Fragment>
	);
};

export default observer(FileCase);
