import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		divider: {
			marginTop: 40,
			marginBottom: 40,
		},

		inputRowBox: {
			display: "flex",
			marginBottom: 10,
		},

		titleField: {
			marginRight: 50,
			width: "100%",
			flex: 1,
		},

		valueField: {
			width: "100%",
			flex: "0 0 150px",
		},
	}),
);

export default useStyles;
