import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
	Tooltip,
	Divider,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import CardForm from "../../../../components/CardForm";
import { useGlobalStore } from "../../../context";
import CreateQuestionDialog from "../_Question/CreateQuestionDialog";
import QuestionDetailsDialog from "../_Question/QuestionDetailsDialog";
import ResultsHelpDialog from "../ResultsHelpDialog";
import ImcHelpDialog from "../ImcHelpDialog";
import QuestionsTable from "./QuestionsTable";
import ResultsTable from "./ResultsTable";
import ImcValuesTable from "./ImcValuesTable";
import { quizValidationSchema } from "./validation";
import Store from "./store";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));
	const [isAddQuestionVisible, setIsAddQuestionVisible] = React.useState(
		false,
	);
	const [isResultsHelpVisible, setIsResultsHelpVisible] = React.useState(
		false,
	);

	const [isImcHelpVisible, setIsImcHelpVisible] = React.useState(false);

	const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState<
		number | undefined
	>(undefined);

	const [hasNoQuestionsError, setHasNoQuestionsError] = React.useState(false);

	const openAddQuestionModal = () => {
		setIsAddQuestionVisible(true);
	};

	const {
		control,
		unregister,
		setValue,
		handleSubmit,
		formState,
	} = useForm<API.NewQuiz>({
		mode: "onChange",
		resolver: yupResolver(quizValidationSchema),
	});

	const onSubmit = (data: API.NewQuiz) => {
		store.createQuiz(data);
	};

	return (
		<CardForm
			title={Strings.quizz.create.title}
			actionsFooter={
				<Button
					disabled={store.loading}
					type="submit"
					variant="contained"
					color="default"
					onClick={() => {
						setValue("questions", store.questions);
						if (store.questions.length === 0) {
							setHasNoQuestionsError(true);
						} else {
							setHasNoQuestionsError(false);
						}
						handleSubmit(onSubmit)();
					}}
				>
					{store.loading ? (
						<CircularProgress />
					) : (
						Strings.admin.create.createButton
					)}
				</Button>
			}
		>
			<CardContent>
				<Controller
					name="name"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Tooltip
							title="Nome que irá identificar o seu questionário. Exemplo: Questionário sobre histórico de doenças na família."
							placement="right"
						>
							<TextField
								variant="standard"
								type="text"
								{...field}
								label="Nome do questionário"
								className={classes.nameField}
								error={!!formState.errors.name}
								helperText={
									formState.errors.name?.message || undefined
								}
							/>
						</Tooltip>
					)}
				/>

				<Divider className={classes.divider} />

				<QuestionsTable
					store={store}
					error={hasNoQuestionsError}
					openAddQuestionModal={openAddQuestionModal}
					setSelectedQuestionIndex={setSelectedQuestionIndex}
				/>

				<Divider className={classes.divider} />

				<ResultsTable
					store={store}
					control={control}
					formState={formState}
					unregister={unregister}
					setIsHelpVisibe={setIsResultsHelpVisible}
				/>

				<Divider className={classes.divider} />

				<ImcValuesTable
					store={store}
					control={control}
					formState={formState}
					unregister={unregister}
					setIsHelpVisibe={setIsImcHelpVisible}
				/>
			</CardContent>
			{isAddQuestionVisible && (
				<CreateQuestionDialog
					isVisible={isAddQuestionVisible}
					setIsVisible={setIsAddQuestionVisible}
					addQuestionToQuiz={store.addQuestionToQuiz}
				/>
			)}
			{selectedQuestionIndex !== undefined && (
				<QuestionDetailsDialog
					question={store.questions[selectedQuestionIndex]}
					setSelectedQuestionIndex={setSelectedQuestionIndex}
				/>
			)}
			<ResultsHelpDialog
				isVisible={isResultsHelpVisible}
				setIsVisible={setIsResultsHelpVisible}
			/>
			<ImcHelpDialog
				isVisible={isImcHelpVisible}
				setIsVisible={setIsImcHelpVisible}
			/>
		</CardForm>
	);
};

export default observer(CreateView);
