import { createStyles } from "@material-ui/core";

export const styles = createStyles({
	root: {
		width: "100%",
		height: "100%",
	},
	container: {
		minHeight: "calc(80vh - 70px)",
	},
	smallContainer: {
		minHeight: "40px",
	},

	footer: {
		paddingLeft: 10,
		paddingRight: 10,
	},
});
